.approve-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.approve-screen .filter-alert-house{
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: red;
}

.approve-screen .header-text {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

.detail-table-block .subdetail-name  {
    margin-top: 5px;
    display: flex;
}

.detail-table-block .subdetail-name .text {
    font-size: 16px;
}

.approve-screen button {
    border-radius: 100px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

/*.approve-screen .align-add-button {*/
/*    text-align: left !important;*/
/*}*/

/*.approve-screen .add-button {*/
/*    border: none;*/
/*    background-image: linear-gradient(#4685BD, #1967AD);*/
/*    color: #FFFFFF;*/
/*    align-items: center;*/
/*    box-shadow: 5px 5px 4px #c7c7c7;*/
/*    padding: 8px 10px;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*}*/

.approve-screen .filter-button {
    position: relative;
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
    margin: 0;
}

.approve-screen .filter-button i {
    color: #979797;
}

.approve-screen .add-button i {
    margin-right: 10px;
}

.approve-screen .search-box {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1;
    margin-right: 10px;

}

.approve-screen .ui.selection.active.dropdown:hover, .approve-screen .ui.selection.active.dropdown, .approve-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.approve-screen .ui.selection.active.dropdown {
    border-top-left-radius: 25px !important;
}

.approve-screen .search-box i.dropdown.icon {
    border: none !important;
}

.approve-screen .search-box .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
}


.approve-screen .search-box .dropdown-search {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

.approve-screen .search-box input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1;

}

.approve-screen .search-box .search-content {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1;
}

.approve-screen .search-box .search-content i.icon {
    font-size: 12px;
    height: auto;
    padding: 0 20px;
    color: #979797;
}

.filter-modal-screen .modal-filter {
    padding: 10px;
    margin: 0px;
}

.filter-modal-screen .modal-filter .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 18px;
    color: var(--main-color);
    font-weight: bold;
}

.filter-modal-screen .modal-filter .header-filter > div {
    display: flex;
    flex: 1;
    align-items: center;
}

.filter-modal-screen .modal-filter .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal-screen .modal-filter .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .filter-detail {
    margin-bottom: 10px;
}

.filter-modal-screen .modal-filter .filter-detail > div {
    margin-bottom: 5px;
    font-size: 12px;
}

.filter-modal-screen .modal-filter .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal-screen .modal-filter .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 12px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

.filter-modal-screen {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.approve-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 50px 20px 20px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
    min-width: 345px;
}

.approve-screen .header-table .space-block1-number {
    width: 25px;
}

.approve-screen .header-table .type-symbol {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    margin-right: 5px;
}

.approve-screen .header-table .column-tools-approve {
    width: 41px;
    display: flex;
    align-items: center;
}

.approve-screen .header-table .row-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.approve-screen .header-table .row-content .block2-content {
    display: flex;
    flex-wrap: wrap;
}

.approve-screen .header-table .row-content .space-content {
    min-width: 250px;
    flex: 2 ;
    display: flex;
}

.approve-screen .block-member-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.approve-screen .block-member-card .none-data-container {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.approve-screen .count-page {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

.approve-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0px 5px;
}

.approve-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
    /*border-color: #EB5757 !important;*/
}

.approve-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.approve-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.approve-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.approve-screen .count-page label {
    padding-right: 5px;

}

.approve-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.approve-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.approve-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.approve-screen .loading-block {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}


@media screen and (max-width: 1047px) {
    .approve-screen .search-box .ui.selection.dropdown {
        min-width: 0 !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .approve-screen .filter-button {
        min-width: 80px;
    }

    .approve-screen .search-box .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .approve-screen .filter-button > label {
        display: none;
    }

    .approve-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0
    }

    .approve-screen .filter-button > i {
        margin: 0 !important;
    }

    .approve-screen .all-page {
        flex-direction: column;
    }


    .approve-screen .header-table .space-block1 {
        width: 90px;
    }

    .approve-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .approve-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .approve-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .approve-screen .header-table {
        font-size: 10px;
    }
    .approve-screen .search-box .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .approve-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .approve-screen .search-box input {
        font-size: 10px;
    }
    .approve-screen .header-text {
        font-size: 20px;
    }
    .approve-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .approve-screen {
        padding: 15px;
    }

    .approve-screen .header-text {
        font-size: 15px;
        padding-bottom: 5px !important;
    }

    .approve-screen .search-box {
        font-size: 8px !important;
    }

    .approve-screen .search-box .dropdown-search {
        font-size: 8px;
    }

    .approve-screen .search-box input {
        font-size: 8px
    }

    .approve-screen .filter-button {
        padding: 5px 8px;
        /*font-size: 8px !important;*/

    }

    .approve-screen .count-page {
        font-size: 8px;
    }

    .approve-screen .count-page .ui.menu .item {
        font-size: 8px;
    }


    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0 !important;
        border-radius: 0;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0 !important;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {
    /*.approve-screen .header-text {*/
    /*    font-size: 12px;*/
    /*}*/

    .approve-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .approve-screen .count-page > label {
        margin-bottom: 5px;
    }

    /*.approve-screen .header-table .row-content .space-content {*/
    /*    width: 80px !important;*/
    /*}*/

}
