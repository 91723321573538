.listhousehold-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.listhousehold-screen .filter-alert-house{
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: red;
}

/*CSS Header Text*/
.listhousehold-screen .header-text-style {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

/*CSS Search Blog*/
.listhousehold-screen .search-container {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1;
    /*overflow: hidden;*/
    margin-right: 10px;
}


/*Search Deropdown*/
.listhousehold-screen .ui.selection.active.dropdown:hover, .listhousehold-screen .ui.selection.active.dropdown, .listhousehold-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.listhousehold-screen .ui.selection.dropdown {
    border-right: solid 2px #c4c4c4;
    border-top: none;
    border-bottom: none;
    border-left: none;
}

.listhousehold-screen .search-container i.dropdown.icon {
    border: none !important;
}

.listhousehold-screen .search-container .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 50px 0 0 50px;
}

.listhousehold-screen .search-container .search-dropdown {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

/*Search Input*/
.listhousehold-screen .search-container .search-input {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1;
}

.listhousehold-screen .search-container  input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1;
}

.listhousehold-screen .search-container .search-input i.icon {
    font-size: 12px;
    height: auto;
    padding:0 20px;
    color: #979797;
}

.listhousehold-screen button {
    border-radius: 50px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

/*Filter button*/
.listhousehold-screen .filter-button {
    position: relative;
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    margin: 0 !important;
    color: #616161;
}

.listhousehold-screen .filter-button i {
    color: #979797;
}

.filter-modal {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.filter-modal .filter-modal-screen{
    padding: 10px;
    margin: 0px;
}

.filter-modal .filter-modal-screen .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 18px;
    color: #00396D;
    font-weight: bold;
}

.filter-modal .filter-modal-screen .header-filter > div {
    display: flex;
    flex: 1;
    align-items: center;
}

.filter-modal .filter-modal-screen .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal .filter-modal-screen .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal .filter-modal-screen .filter-detail {
    margin-bottom: 10px;
}

.filter-modal .filter-modal-screen .filter-detail > div {
    margin-bottom: 5px;
    font-size: 14px;
}

.filter-modal .filter-modal-screen .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal .filter-modal-screen .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
}

.filter-modal .filter-modal-screen .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 14px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

/*Table Household*/
.listhousehold-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 50px 20px 20px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
    min-width: 345px;
}

.listhousehold-screen .header-table .number-content {
    width: 25px;
}

.listhousehold-screen .header-table .topic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.listhousehold-screen .header-table .topic-content .topic-detail-content {
    display: flex;
    flex-wrap: wrap;
}

.listhousehold-screen .header-table .topic-content .space-content {
    flex: 2 ;
    min-width: 250px;
    display: flex;
}

/*Show detail household */
.listhousehold-screen .blog-member-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.listhousehold-screen .blog-member-card .none-data-container {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

/*Number of page*/
.listhousehold-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.listhousehold-screen .count-page {
    font-family: 'Kanit', sans-serif !important;
    font-size: 10px !important;
}

.listhousehold-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.listhousehold-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.listhousehold-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif !important;
    font-size: 10px !important;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0 5px;
}

.listhousehold-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
}

.listhousehold-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.listhousehold-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.listhousehold-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.listhousehold-screen .count-page label {
    padding-right: 5px;

}


@media screen and (max-width: 1047px) {
    .listhousehold-screen .search-container .ui.selection.dropdown {
        min-width: 0 !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .listhousehold-screen .filter-button {
        min-width: 80px;
    }

    .listhousehold-screen .search-container .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .listhousehold-screen .filter-button > label {
        display: none;
    }

    .listhousehold-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0
    }

    .listhousehold-screen .filter-button > i {
        margin: 0 !important;
    }

    .listhousehold-screen .all-page {
        flex-direction: column;
    }


    .listhousehold-screen .header-table .space-block1 {
        width: 90px;
    }

    .listhousehold-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .listhousehold-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .listhousehold-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .listhousehold-screen .header-table {
        font-size: 12px;
    }
    .listhousehold-screen .search-container .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .listhousehold-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .listhousehold-screen .search-container input {
        font-size: 10px;
    }
    .listhousehold-screen .header-text-style {
        font-size: 20px;
    }
    .listhousehold-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .listhousehold-screen {
        padding: 15px;
        margin-right: 15px;
    }

    .listhousehold-screen .header-text-style {
        font-size: 13px;
        padding-bottom: 5px !important;
    }

    .listhousehold-screen .search-container {
        font-size: 10px !important;
    }

    .listhousehold-screen .search-container .dropdown-search {
        font-size: 10px;
    }

    .listhousehold-screen .search-container input {
        font-size: 10px
    }

    .listhousehold-screen .filter-button {
        padding: 5px 8px;

    }

    .listhousehold-screen .count-page {
        font-size: 8px;
    }

    .listhousehold-screen .count-page .ui.menu .item {
        font-size: 8px;
    }


    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0 !important;
        border-radius: 0;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0 !important;
    }

    .listhousehold-screen .search-container .search-input i.icon {
        padding: 0 15px 0 0;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {


    .listhousehold-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .listhousehold-screen .count-page > label {
        margin-bottom: 5px;
    }


}

