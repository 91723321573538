@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --main-color: rgb(18,95,103);
    --main2-color: rgb(40,130,140);
}

.main-container {
    transition: .5s;
    margin-left: 250px;
    padding-top: 70px;
}
.App{
    min-height: 100vh;
}
html,body{
    font-family: 'Kanit', sans-serif !important;
    background-color: #EBEBEB !important;
    position: relative;
    z-index: -99;
}

.height-light {
    font-size: 20px;
    color: red;
}

h2 {
    font-family: 'Kanit', sans-serif !important;
}

:root {
    --screen-height: 100vh;
}

.login-screen, .login-screen .container {
    min-height: 100vh;
    position: relative;
}

.height-light {
    font-family: 'Kanit', sans-serif;
    margin: 0;
    padding: 30px 0 0 30px;
    font-size: 20px;
    color: #ffffff;
}

.main-login {
    padding: 5px;
    height: 100vh;
    position: relative;
}

.background-login {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #16576b;
    z-index: -99;
}

.background-login::after {
    content: "";
    background-image: url(/static/media/silk_pattern.b8c7f2e9.png);
    background-size: 80px 80px;
    opacity: 0.09;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    top: 0;
    left: -200px;
    bottom: -200px;
    right: 0;
    position: absolute;
    z-index: -99;
}

.block-top-right {
    height: 100vh;
    height: var(--screen-height);
    width: calc(100vw - 300px);
    background-color: #F0F5F9;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    z-index: -97;
}

.block-bottom-right {
    z-index: -98;
    width: 100vw;
    height: 250px;
    background-color: #FFF;
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.container {
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    padding: 20px;
    flex: 1 1;
}

.logo-container-login {
    display: flex;
    /*flex: 1;*/
    align-items: center;
}

.logo-header-login {
    border-radius: 100px;
    margin-right: 10px;
    height: 50px;
    width: 67px;
}

.login-screen .logo-login {
    display: none;
}

.logo-text-login {
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
}

.logo-text-sub-login {
    font-size: 14px;
    font-weight: 100;
    padding-top: 0;
    border-top: solid 1px #ffffff2b;
    margin-top: 5px;
    color:#FFF;
}

.body-container {
    display: flex;
    flex: 1 1;
    width: 100%;
    /*align-items: center;*/
}

.app-container {
    min-width: 500px;
    /*align-self: flex-end;*/
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.app-img {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
}


.app-img img {
    max-width: 600px;
    max-height: 500px;
    height: auto;
    width: 100%;
    top: 80px;
    position: relative;
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes example {
    0% {
        top: 80px
    }
    50% {
        top: 85px;
    }
    100% {
        top: 80px;
    }
}

@keyframes example {
    0% {
        top: 80px
    }
    50% {
        top: 85px;
    }
    100% {
        top: 80px;
    }
}

@-webkit-keyframes mini-logo {
    0% {
        top: 0
    }
    50% {
        top: 5px;
    }
    100% {
        top: 0;
    }
}

@keyframes mini-logo {
    0% {
        top: 0
    }
    50% {
        top: 5px;
    }
    100% {
        top: 0;
    }
}

.download-here {
    display: flex;
    flex-direction: column;
    /*flex: 1;*/
}

.download-text {
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
    color: #FFFFFF;
}

.download-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.download-img {
    width: auto;
    height: 100px;
    margin-right: 20px;
    cursor: pointer;

}

.app-img-mobile {
    display: none;
}

.container-login {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-right: 20%;
}

.container-login .message-error {
    width: 100% !important;
    padding: 10px !important;
    border-radius: 10px !important;
}


.welcome-text {
    font-family: 'Kanit', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: var(--main-color);
    margin-top: 20px;
}

.system-text {
    background-color: #f0f5f9;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: var(--main2-color);
    margin-top: 20px;
}

.border-input-login {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: solid 1px #c4c4c4;
    border-radius: 100px;
    margin-top: 20px;
    padding: 5px 10px;
    width: 100%;
    color: #313337;
}

.invalid-border-input-login {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: solid 1px red;
    border-radius: 100px;
    margin-top: 20px;
    padding: 5px 10px;
    width: 100%;
    color: #313337;
}

.border-input-login i {
    margin-top: -7px !important;
}

.input-login {
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
    /*width: 350px;*/
    flex: 1 1;
    font-family: 'Kanit', sans-serif;
}

.invalid-text {
    color: red;
    font-size: 12px;
    font-family: 'Kanit', sans-serif;
    margin: 3px 12px 0px;
    align-self: flex-start;
}

.forgot-password {
    font-family: 'Kanit', sans-serif;
    font-size: 14px;
    align-self: flex-end;
    margin-right: 20px;
    color: #00396D;
    background-color: #f0f5f9;
    padding: 0px 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.button-login {
    cursor: pointer;
    outline: none;
    background-color: var(--main-color);
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Kanit', sans-serif;
    border-color: #FFFFFF;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
}

.button-loading {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    background-color: #002546;
    color: #a5a2a2;
    font-size: 14px;
    font-family: 'Kanit', sans-serif;
    border-color: #a5a2a2;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
}

.container-login img.phone-logo {
    display: none;
}

/* Ipad Screen */
@media screen and (max-width: 850px) {
    .system-text {
        background-color: transparent;
        margin: 0;
    }

    .body-container {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .container-login {
        background-color: #ffffffab;
        margin: 30px;
        border-radius: 10px;
        padding: 20px 120px;
        align-items: center;
        flex: 1 1;
    }

    .welcome-text {
        font-family: 'Kanit', sans-serif;
        font-size: 30px;
        font-weight: bold;
        color: var(--main-color);
        margin-top: 0;
    }

    .login-screen .logo-login {
        display: block;
        border-radius: 100px;
        margin-bottom: 20px;
    }

    .app-container {
        justify-content: space-between;
        flex-direction: row-reverse;
        flex: 1 1;
        padding-bottom: 120px;
    }

    .download-container {
        flex-direction: column;
    }

    .download-img {
        height: 70px;
        margin-bottom: 20px;
    }

    .app-img {
        max-width: 360px;
        max-height: 253px;
        width: 100%;
        height: 100%;
    }

    .download-here {
        padding-left: 30px;
    }

    .forgot-password {
        background-color: transparent !important;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 650px) {
    .app-img-mobile {
        display: none;
    }

    .background-login::after {
        -webkit-transform: scale(1.4) rotate(30deg);
                transform: scale(1.4) rotate(30deg);
        opacity: 0.05;
    }

    .block-bottom-right {
        display: none;
    }

    .container-login {
        margin-top: 0;
        background-color: transparent;
        padding: 20px;
    }

    .login-screen .logo-login {
        display: none;
    }

    .welcome-text {
        font-size: 20px;
        color: #FFFFFF;
    }

    .system-text {
        font-family: 'Kanit', sans-serif;
        font-size: 12px;
        color: #FFFFFF;
    }

    .app-container {
        min-width: 200px;
        flex: 1 1;
        padding-bottom: 0;
    }

    .app-img {
        display: none;
    }

    .download-container {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0;
    }

    .download-container .download-img:last-of-type {
        margin-right: 0;
    }

    .download-here {
        padding-left: 0;
        flex: 1 1;
    }

    .body-container {
        flex-wrap: wrap-reverse;
    }

    .logo-header-login {
        height: 45px;
        width: 67px;
    }

    .logo-text-login {
        font-size: 12px;
    }

    .container-login img.phone-logo {
        display: block;
        width: 200px;
        height: auto;
        top: 80px;
        position: relative;
        -webkit-animation-name: mini-logo;
                animation-name: mini-logo;
        -webkit-animation-duration: 3s;
                animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
    }


    .block-top-right {
        height: 320px;
    }

    .forgot-password {
        color: #FFF;
    }

}


.nav.toggle .toggle-menu {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.nav.toggle {
    width: 80px;
}

.nav.toggle ~ .main-container{
    margin-left: 80px;
}

.nav {
    overflow: auto;
    background: rgb(0, 57, 109);
    background: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    width: 250px;
    height: 100vh;
    position: fixed;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    transition: 0.5s;
    z-index: 99;
}

.nav::after {
    content: "";
    background-image: url(/static/media/silk_pattern.b8c7f2e9.png);
    background-size: 80px 80px;
    opacity: 0.04;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -99;
}


/* Ipad Screen */
@media screen and (max-width: 850px) {
    .nav.toggle {
        width: 80px;
        height: 70px;
        overflow: hidden;
        background: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    }
    .nav{
        position: fixed;
        width: 100vw;
        overflow-x: hidden;
    }
    .nav ~ .main-container{
        margin-left: 0 !important;
    }
}

.menu-item a {
    width: 100%;
}
.nav .toggle-menu {
    position: absolute;
    font-size: 25px !important;
    top: 25px;
    right: 25px;
    color: #FFF;
    cursor: pointer;
    transition: .5s;
}

.nav .toggle-menu:hover {
    color: #cccccc;
}

.nav .logo {
    transition: .5s;
    display: block;
    margin: 60px auto 10px auto;
    width: 130px;
    height: 100px;
}

.nav.toggle .logo{
    margin-top: 70px;
    width: 50px;
    height: 40px;
}

.nav > h1 {
    font-family: 'Kanit', sans-serif;
    text-align: center;
    color: #FFF;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    transition: .5s;
}
.nav > h3 {
    margin: 0 0 15px 0;
    text-align: center;
    color: #FFF;
    font-weight: normal;
}

.nav.toggle > h1 {
    font-size: 0;
    margin: 0;
}

.nav.toggle > h3 {
    font-size: 0;
    margin: 0;
}

.nav li.menu-item a {
    font-family: 'Kanit', sans-serif !important;
}

.menu-item {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    margin-left: 45px !important;
}

.nav.toggle .menu-item{
    margin-left: 0 !important;
    flex-direction: column;
    height: 40px;
    text-align: center;
}
.nav .menu-text{
    transition: .5s;
}
.nav.toggle .menu-text{
    font-size: 0;
}

.nav ul {
    position: relative;
    padding: 0;
    margin: 0;
}

.nav ul li a {
    color: #FFF;
    position: relative;
    z-index: 2;
    display: block;
    padding: 10px;
    transition: 0.5s;
}

.nav ul li i {
    position: relative;
    z-index: 2;
    transition: 0.5s;
    margin-top: -5px;
    margin-right: 10px;
}
.nav.toggle ul li i {
    margin: 0;
}

.nav ul li.active a .menu-text{
    color: var(--main-color);
}

.nav ul li {
    margin: 10px 0;
    height: 40px;
    list-style-type: none;
}

.nav li.slide {
    display: none;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    height: 40px;
    margin: 10px 0 0 30px;
    position: absolute;
    width: calc(100% - 30px);
    right: 0;
    z-index: 0;
    background-color: #EBEBEB;
    transition: 0.5s;
}
.nav.toggle li.slide {
    width: calc(100% - 10px);
}


.nav li.slide img.top {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
}

.nav li.slide img.bottom {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    bottom: -30px;
}

@-webkit-keyframes menu {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu2 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu2 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu3 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu3 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu4 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu4 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu5 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu5 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu6 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu6 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu7 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu7 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu8 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu8 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu9 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu9 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu10 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu10 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu11 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu11 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu12 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu12 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@-webkit-keyframes menu13 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu13 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

.nav li:nth-child(1).active ~ .slide {
    display: block;
    -webkit-animation-name: menu;
            animation-name: menu;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: -10px;
}

.nav li:nth-child(2).active ~ .slide {
    display: block;
    -webkit-animation-name: menu2;
            animation-name: menu2;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 40px;
}

.nav li:nth-child(3).active ~ .slide {
    display: block;
    -webkit-animation-name: menu3;
            animation-name: menu3;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 90px;
}

.nav li:nth-child(4).active ~ .slide {
    display: block;
    -webkit-animation-name: menu4;
            animation-name: menu4;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 140px;
}

.nav li:nth-child(5).active ~ .slide {
    display: block;
    -webkit-animation-name: menu5;
            animation-name: menu5;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 190px;
}

.nav li:nth-child(6).active ~ .slide {
    display: block;
    -webkit-animation-name: menu6;
            animation-name: menu6;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 240px;
}

.nav li:nth-child(7).active ~ .slide {
    display: block;
    -webkit-animation-name: menu7;
            animation-name: menu7;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 290px;
}

.nav li:nth-child(8).active ~ .slide {
    display: block;
    -webkit-animation-name: menu8;
            animation-name: menu8;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 340px;
}

.nav li:nth-child(9).active ~ .slide {
    display: block;
    -webkit-animation-name: menu9;
            animation-name: menu9;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 390px;
}

.nav li:nth-child(10).active ~ .slide {
    display: block;
    -webkit-animation-name: menu10;
            animation-name: menu10;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 440px;
}

.nav li:nth-child(11).active ~ .slide {
    display: block;
    -webkit-animation-name: menu11;
            animation-name: menu11;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 490px;
}

.nav li:nth-child(12).active ~ .slide {
    display: block;
    -webkit-animation-name: menu12;
            animation-name: menu12;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 540px;
}

.nav li:nth-child(13).active ~ .slide {
    display: block;
    -webkit-animation-name: menu13;
            animation-name: menu13;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 590px;
}

@-webkit-keyframes toggle-menu {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu2 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu2 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu3 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu3 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu4 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu4 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu5 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu5 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu6 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu6 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu7 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu7 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu8 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu8 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu9 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu9 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu10 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu10 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu11 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu11 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu12 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu12 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@-webkit-keyframes toggle-menu13 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu13 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}


.nav li:nth-child(1).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu;
            animation-name: toggle-menu;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: -10px;
}

.nav li:nth-child(2).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu2;
            animation-name: toggle-menu2;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 40px;
}

.nav li:nth-child(3).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu3;
            animation-name: toggle-menu3;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 90px;
}

.nav li:nth-child(4).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu4;
            animation-name: toggle-menu4;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 140px;
}

.nav li:nth-child(5).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu5;
            animation-name: toggle-menu5;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 190px;
}

.nav li:nth-child(6).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu6;
            animation-name: toggle-menu6;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 240px;
}

.nav li:nth-child(7).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu7;
            animation-name: toggle-menu7;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 290px;
}

.nav li:nth-child(8).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu8;
            animation-name: toggle-menu8;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 340px;
}

.nav li:nth-child(9).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu9;
            animation-name: toggle-menu9;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 390px;
}

.nav li:nth-child(10).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu10;
            animation-name: toggle-menu10;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 440px;
}

.nav li:nth-child(11).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu11;
            animation-name: toggle-menu11;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top: 490px;
}

.nav li:nth-child(12).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu12;
            animation-name: toggle-menu12;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top:540px;
}

.nav li:nth-child(13).active ~ .slide {
    display: block;
    -webkit-animation-name: toggle-menu13;
            animation-name: toggle-menu13;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    top:590px;
}

.top-bar {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: #FFF;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    font-family: 'Kanit', sans-serif !important;
}
.top-bar .ui.tiny.avatar.image{
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
}
.top-bar .account-menu{
    display: flex;
    align-items: center;
}

.top-bar .account-menu .account-pic-profile{
    border-radius: 25px;
    width: 50px;
}

.top-bar .account-info{
    text-align: right;
    padding-right: 10px;
}
.top-bar .account-info .topic{
    font-size: 18px;
    color: #00396d;
}

.top-bar .labeled.dropdown{
    display: flex;
    align-items: center;
}

.top-bar .permission{
    font-size: 14px;
    color: #5c5c5c;
}


.route-loader {
    background-color: #ffffff;
    padding:20px;
    margin:20px;
}
.route-loader .ui.placeholder{
    max-width: 850px;
    width: 100%;
}

.page-error{
    font-family: 'Kanit', sans-serif;
    display: flex;
    min-height: calc(100vh) ;
    align-items: center;
    padding: 40px;
    flex-wrap: wrap-reverse;
}
.page-error .container-text{
    /*flex: 1;*/
    margin-top: -150px;
    min-width: calc(50% - 50px);
    text-align: right;
    padding-right: 40px;
}
.page-error .container-text h1{
    font-family: 'Kanit', sans-serif !important;
    font-size: 45px;
    font-weight: bold;
}

.page-error .container-text .detail{
    margin-top: 30px;
    font-size: 20px;
    padding-right: 20px;
}
.page-error .container-text .detail div{
    margin: 10px 0;
}

.page-error .container-text .ex-detail {
    margin-top: 50px;
    /*text-align: center;*/
    margin-right: 60px;
}

.page-error .container-text .ex-detail button{
    cursor: pointer;
    outline: none;
    background-color: #00396D;
    color: #FFF;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    font-family: 'Kanit', sans-serif;
    font-weight: normal !important;
    margin-top: 5px;
}
.page-error .container-text .ex-detail button:active{
    background-color: #00274f;
}

.page-error .container-img{
    flex: 1 1;
    margin-top: -150px;
}
.page-error .container-img img{
    width: 100%;
    max-width: 500px;
    min-width: 350px;
}

/* Ipad Screen */
@media screen and (max-width: 1045px) {
    .page-error{
        flex-wrap: wrap-reverse;
    }

    .page-error .container-img {
        text-align: center;
    }
    .page-error .container-img img{
        max-width: 350px;
        min-width: 100px;
    }

    .page-error .container-text{
        align-self: flex-end;
        margin-top: 0;
        width: 100%;
        text-align: center;
        padding: 0;
    }
    .page-error .container-text .detail{
        padding: 0;
    }
    .page-error .container-img{
        align-self: flex-start;
        flex: 1 1;
        margin-top: 0;
    }

    .page-error .container-text .ex-detail{
        margin-right: 0;
    }
}

h1 {
	font-family: 'Kanit', sans-serif !important;
}

.address-item span.active {
	color: green
}

.address-item span {
	color: #8e8e8e
}

.ui.input.error > input {
	background-color: #fff6f6;
	border-color: #d01919;
	color: #000000;
	box-shadow: none;
}

.ui.input.success > input {
	background-color: #f6fff6;
	border-color: rgb(78, 165, 114);
	color: #000000;
	box-shadow: none;
}

.field.error .input > input {
	background-color: #fff6f6;
	border-color: #e0b4b4;
	color: #000000;
	box-shadow: none;
}

.field.success .input > input {
	background-color: #f6fff6;
	border-color: rgb(78, 165, 114);
	color: #000000;
	box-shadow: none;
}

/* .error {
	background-color: #fff6f6;
	color: #000000;
	box-shadow: none;
	flex-grow: 1;
	display: flex;
	border: solid 1px #9f3a38;
	padding: 10;
	border-radius: 5;
} */

.ui.basic.label.error {
	background: none #fff6f6;
	border: 1px solid #d01919;
	color: rgba(0, 0, 0, .87);
	box-shadow: none;
}

.ui.basic.label.success {
	background: rgb(246, 255, 246);
	border: 1px solid rgb(78, 165, 114);
	color: rgba(0, 0, 0, .87);
	box-shadow: none;
}

.ui.selection.dropdown.error {
	background: #fff6f6;
	border-color: #e0b4b4;
}

.ui.selection.dropdown.success {
	background: rgb(246, 255, 246);
	border-color: rgb(78, 165, 114);
}

.flexEnd {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	padding: 0 !important;
}

.buttonSave {
	font-family: 'Kanit', sans-serif !important;
	border-radius: 50px;
	margin-left: 30px !important;
	padding: 10px 50px !important;
}

.buttonCancel{
  color: #00396D !important;
  margin-right: 10px !important;
  padding-right: 2px !important;
}

.labelRadio{
	flex-grow: 1;
	display: flex;
	border: 1px solid rgba(118, 118, 118, 0.28);
	padding: 10px;
	border-radius: 5px;
}

.labelRadioError{
	flex-grow: 1;
	display: flex;
	border: 1px solid #e0b4b4;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff6f6;
	color: #e0b4b4;
}

.labelRadioSuccess{
	flex-grow: 1;
	display: flex;
	border: 1px solid rgb(78, 165, 114);
	padding: 10px;
	border-radius: 5px;
	background-color:rgb(246, 255, 246);
	color: rgb(78, 165, 114);
}

.displayCenter {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.buttonCancelCross {
	font-family: 'Kanit', sans-serif !important;
	background-color: white !important;
	padding-right: 0 !important;
}

.UCancel {
	color: #00396d;
	margin: left;
}

.buttonClose {
	background-color: white !important;
	border-color: #fff;
}

.heading {
	background-image: linear-gradient(90deg, rgba(0,57,109,1) 0%, rgba(42,133,215,1) 100%);
	margin: 5px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px;
  color: #FFFFFF;
}

.displayFlexEnd {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	padding: 0 !important;
}

.set_margin_left_bottom {
	margin-top: 20px;
	margin-bottom: 20px;
}

.set_padding_bottom {
	padding-bottom: 0% !important;
}

.set_color_red {
	color: red !important;
}

.set_padding_top {
	padding-top: 10px !important;
}

.close-button {
  border-color: #FFFFFF !important;
  background-color: #FFFFFF !important;
}

.padding-left {
  padding-left: 100%;
}

h3 {
    font-family: 'Kanit', sans-serif !important;
}

input {
    font-family: 'Kanit', sans-serif !important;
}

label {
    font-family: 'Kanit', sans-serif !important;
}

.modal-add-member .visible.menu.transition {
    width: 450px !important;
}

.modal-add-member .address-item span.active {
    color: green
}

.modal-add-member .address-item span {
    color: #8e8e8e
}

.modal-add-member .ui.search.dropdown > .menu {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.add-member-modal {
    display: flex;
    margin-bottom: 20px;
    font-family: 'Kanit', sans-serif !important;
}

.add-member-modal h1 {
    font-family: 'Kanit', sans-serif !important;
}

.ui.input.error > input {
    background-color: #fff6f6;
    border-color: #d01919;
    color: #000000;
    box-shadow: none;
}

.ui.input.success > input {
    background-color: #f6fff6;
    border-color: rgb(78, 165, 114);
    color: #000000;
    box-shadow: none;
}

.field.error .input > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #000000;
    box-shadow: none;
}

.field.success .input > input {
    background-color: #f6fff6;
    border-color: rgb(78, 165, 114);
    color: #000000;
    box-shadow: none;
}

/* .error {
	background-color: #fff6f6;
	color: #000000;
	box-shadow: none;
	flex-grow: 1;
	display: flex;
	border: solid 1px #9f3a38;
	padding: 10;
	border-radius: 5;
} */

.ui.basic.label.error {
    background: none #fff6f6;
    border: 1px solid #d01919;
    color: rgba(0, 0, 0, .87);
    box-shadow: none;
}

.ui.basic.label.success {
    background: rgb(246, 255, 246);
    border: 1px solid rgb(78, 165, 114);
    color: rgba(0, 0, 0, .87);
    box-shadow: none;
}

.ui.selection.dropdown.error {
    background: #fff6f6;
    border-color: #e0b4b4;
}

.ui.selection.dropdown.success {
    background: rgb(246, 255, 246);
    border-color: rgb(78, 165, 114);
}

.flexEnd {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding: 0 !important;
}

.button-save {
    font-family: 'Kanit', sans-serif !important;
    border-radius: 50px;
    margin-left: 30px !important;
    padding: 10px 50px !important;
}

.buttonCancel {
    color: #00396d !important;
    margin-right: 10px !important;
    padding-right: 2px !important;
}

.labelRadio {
    flex-grow: 1;
    display: flex;
    border: 1px solid rgba(118, 118, 118, 0.28);
    padding: 10px;
    border-radius: 5px;
}

.labelRadioError {
    flex-grow: 1;
    display: flex;
    border: 1px solid #e0b4b4;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff6f6;
    color: #e0b4b4;
}

.labelRadioSuccess {
    flex-grow: 1;
    display: flex;
    border: 1px solid rgb(78, 165, 114);
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(246, 255, 246);
    color: rgb(78, 165, 114);
}

.display-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.button-cancel-cross {
    font-family: 'Kanit', sans-serif !important;
    background-color: white !important;
    padding-right: 0 !important;
}

.u-cancel {
    color: #00396d;
    margin: left;
}

.buttonClose {
    background-color: white !important;
    border-color: #fff;
}

.heading {
    background-image: linear-gradient(90deg, rgba(0, 57, 109, 1) 0%, rgba(42, 133, 215, 1) 100%);
    margin: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
    color: #FFFFFF;
}

.displayFlexEnd {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding: 0 !important;
}

.set-margin-left-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
}

.set-padding-bottom {
    padding-bottom: 0% !important;
}

.set-color-red {
    color: red !important;
}

.set-padding-top-imp {
    padding-top: 10px !important;
}

.close-button {
    border-color: #FFFFFF !important;
    background-color: #FFFFFF !important;
}

.padding-left {
    padding-left: 100%;
}

.set-margin-bottom {
    margin-bottom: 20px;
}

.btn-upload-img {
    padding: 10px;
    border: solid 1px #C4C4C4;
    border-radius: 15px;
}

.set-margin-top {
    margin-top: 15px;
}

.display-center {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.heading {
	background-image: linear-gradient(90deg, rgba(0,57,109,1) 0%, rgba(42,133,215,1) 100%);
	margin: 5px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px;
  color: #FFFFFF;
}

.button-edit {
  border-radius: 50px !important;
  margin-left: 10px !important;
  background-color: #E6A935 !important;
  color: #FFFFFF !important;
  /* background-image: linear-gradient(#FFFFFF, #E6A935) !important; */
}

.button-reset-password {
  border-radius: 50px !important;
  margin-right: 10px !important;
  background-color: #1967AD !important;
  color: #FFFFFF !important;
}

.set-icon-yellow {
  color: #E6A935 !important;
  background-color: #FFFFFF !important;
}

.set-icon-blue {
  color: #1967AD !important;
  background-color: #FFFFFF !important;
}

.border-bottom-solid {
  border-bottom: 1px solid #cccccc;
}

.set-label-padding-top {
  padding-top: 14px !important;
  padding-bottom: 14px;
  display: flex !important;
	align-items: center;
	justify-content: flex-end;
	/* padding: 0 !important; */
}

.set-padding-top {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.flex-end {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	padding: 0 !important;
}

.pic-profile {
  margin: auto;
  width: 200px;
  border-radius: 100px;
}

.close-button {
  border-color: #FFFFFF !important;
  background-color: #FFFFFF !important;
}

.padding-left {
  padding-left: 100%;
}

.padding-bottom {
  padding-bottom: 0px;
}

.padding-top {
  padding-top: 0px !important;
}

.set-display-center {
  display: flex !important;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.set-margin-bottom-top {
  margin: 0px;
  margin-bottom: 10px;
  padding: 0px !important;
}

.set-display-margin-bottom{
  display: flex;
  margin-bottom: 20px;
}
.detail-profile-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.detail-profile-screen .header-profile {
    font-size: 20px !important;
    font-family: 'Kanit', sans-serif !important;
    color: #464646;
}

.detail-profile-screen .profile-user {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
}

.detail-profile-screen .container-subheader {
    display: flex;
    padding: 10px 20px;
    border-bottom: solid 2px #EBEBEB;

}

.detail-profile-screen .container-subheader .subheader {
    display: flex;
    align-items: center;
    flex: 1 1;
}

.detail-profile-screen .container-subheader .subheader .icon-header {
    font-size: 28px;
    margin-right: 8px !important;
    color: #013B70;
}

.detail-profile-screen .container-subheader .subheader .name-user {
    font-size: 18px;
    font-weight: bold;
}

.detail-profile-screen .container-subheader .subheader .id-card {
    font-size: 12px;
    color: #B7B7B7;
    font-weight: bold;
}

.detail-profile-screen .container-subheader .ui.divider {
    margin: 0px !important;
}

.detail-profile-screen .container-content {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.detail-profile-screen .container-content .img-profile {
    max-width: 170px;
    margin: 10px 50px;
    border-radius: 100px;
}

.detail-profile-screen .container-content .divider-profile {
    border-left: solid 1px #EBEBEB;
    min-height: 450px;
    margin: 0px 10px;
}

.detail-profile-screen .container-content .container-content-input {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 10px 50px;
}

.detail-profile-screen .profile-user .container-content .container-information {
    display: flex;
    background-color: #EBEBEB;
    padding: 8px;
    border-radius: 22px;
    align-items: center;
    margin-bottom: 10px;
}


.detail-profile-screen .profile-user .container-content .container-information .container-icon-topic {
    padding: 5px 6px;
    border-radius: 15px;
    background-image: linear-gradient(#4786BE, #1967AD);
    margin-right: 10px;

}

.detail-profile-screen .profile-user .container-content .container-information .icon-topic {
    color: #FFFFFF;
    margin: 0px !important;

}

.detail-profile-screen .profile-user .container-content .container-information .detail-topic {
    display: flex;
    flex-direction: column;
}

.detail-profile-screen .profile-user .container-content .container-information .detail-topic .detail-sub-topic {
    font-size: 11px;
    color: #a2a2a2;
}

.detail-profile-screen .profile-user .container-content .container-information .detail-topic span {
    color: #4a4a4a;
}

.detail-profile-screen .container-button-action {
    display: flex;
}

.detail-profile-screen .container-button-action .button-action {
    border-radius: 20px;
    padding: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detail-profile-screen .container-button-action .button-action .icon-password {
    background-color: #FFFFFF;
    padding: 15px;
    border-radius: 15px;
    color: #1967AD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-profile-screen .container-button-action .button-action .icon-edit {
    background-color: #FFFFFF;
    padding: 12px;
    border-radius: 15px;
    color: #E6A935;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-profile-screen .container-button-action .button-action span {
    color: #FFFFFF;
    font-family: 'Kanit', sans-serif;
    padding: 0px 10px;
    font-size: 12px !important;
}

@media screen and (max-width: 660px) {
    /*.detail-profile-screen .container-content*/
}

/*size Ipad*/
@media screen and (max-width: 680px) {

    .detail-profile-screen .container-button-action {
        margin-bottom: 10px;
    }

    .detail-profile-screen .container-content {
        flex-direction: column;
    }

    .detail-profile-screen .container-content .divider-profile {
        display: none;
    }

    .detail-profile-screen .container-content .img-profile {
        margin: 10px 55px;
    }

    .detail-profile-screen .container-content .container-content-input {
        margin: 10px 55px;
    }

}


/*size mobile*/
@media screen and (max-width: 480px) {
    .detail-profile-screen .container-subheader {
        flex-direction: column;
    }

    .detail-profile-screen .header-profile {
        display: flex;
        flex: 1 1;
        justify-content: center;
        padding-bottom: 10px;
    }

    .detail-profile-screen .container-button-action .button-action {
        flex: 1 1;
    }

}




.change-password-screen {
    font-family: 'Kanit', sans-serif;
    display: flex !important;
    flex-direction: column;
}


.change-password-screen .header-change-password {
    display: block;
    background: #fff;
    margin: 0;
    padding: 10px;
    box-shadow: none;
    color: rgba(0, 0, 0, .85);
    border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.change-password-screen .header-change-password .header-container {
    display: flex;
    flex-direction: row;
}

.change-password-screen .header-change-password .header-container .header-text-container {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.change-password-screen .header-change-password .header-container .header-text-container .icon-password {
    background-color: #1967AD;
    padding: 15px;
    border-radius: 15px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-right: 10px;
}

.change-password-screen .header-change-password .header-container .header-text-container .header-text {
    font-family: 'Kanit', sans-serif;
}

.change-password-screen .header-change-password .header-container .close-modal-button {
    border: none;
    outline: none;
    padding: 0px 3px 1px 6px;
    background-color: #db2828;
    cursor: pointer;
    border-radius: 15px;
    color: #FFF;
    max-height: 25px;
}

.change-password-screen .content-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.change-password-screen .content-container .container-input {
    display: flex;
    flex: 1 1;
    align-items: baseline;
    font-size: 12px;
    margin: 5px 0px;
}


.change-password-screen .content-container .container-input > label {
    min-width: 150px;
}

.change-password-screen .content-container .container-input .container-input-password {
    display: flex;
    flex-direction: column;
}

.change-password-screen .content-container .container-input .container-input-password .border-input-password {
    border: solid 1px #979797;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.change-password-screen .content-container .container-input .container-input-password .border-input-password-error {
    border-radius: 20px;
    display: flex;
    align-items: center;
    border-width: 1px;
    border-image: none;
    border-style: solid;
    border-color: #f98484;
    outline: none;
    background-color: #f7ecec;
}

.change-password-screen .content-container .container-input .container-input-password .input-password {
    /*flex: 1;*/
    min-width: 300px;
    border-radius: 20px;
    border: none;
    padding: 5px 10px;
    border-image: none;
    font-family: 'Kanit', sans-serif !important;
    outline: none;
}

.change-password-screen .content-container .container-input .container-input-password .input-password-error {
    min-width: 300px;
    border-radius: 20px;
    padding: 5px;
    border-width: 0px;
    border-image: none;
    border-style: solid;
    font-family: 'Kanit', sans-serif !important;
    outline: none;
    background-color: #f7ecec;
}

.change-password-screen .content-container .container-input .container-input-password .input-detail-error {
    font-size: 11px;
    color: red;
    padding-left: 5px;

}

.change-password-screen .content-container .container-input .container-input-password .text-success {
    color: #7dbf29;
    font-size: 10px;
}

.change-password-screen .content-container .container-input .container-input-password .text-normal {
    color: red;
    font-size: 10px;
    margin-left: 10px;
}

.change-password-screen .content-container .container-input .container-input-password .icon-success {
    margin-left: 10px;
}

.change-password-screen .content-container .container-input .container-input-password .see-button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: #a4a4a4;
}

.change-password-screen .button-action {
    display: flex;
    margin-top: 15px;
    align-items: center;
}

.change-password-screen .button-action > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}

.change-password-screen .button-action .button-save-password {
    border: none;
    font-size: 12px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

/*ui.modal {*/
/*    width: 100%;*/
/*    margin: 0px !important;*/
/*    !*position: absolute; */
/*!* display: none; */
/*!* z-index: 1001;*/

/* */

@media only screen and (max-width: 767px) {
    .ui.modal {
        width: 100%;
        margin: 0px;
    }
}


/*Mobile Screen*/
@media screen and (max-width: 540px) {
    .ui.dimmer {
        padding: 0px !important;
    }

    .ui.modal {
        width: 100% !important;
    }

    .change-password-screen {
        flex: 1 1 !important;
        margin: 0px !important;
        border-radius: 0px !important;
        position: static !important;
    }

    .change-password-screen .content-container .container-input > label {
        min-width: 100px;
    }

    .change-password-screen .content-container .container-input .input-password {
        min-width: 200px;
    }

    .change-password-screen .ui.modal {
        width: 100% !important;
        margin: 0 !important;
    }

    .change-password-screen .ui.modal > .header {
        padding: 10px !important;
    }

    .change-password-screen .ui.modal {
        width: 100% !important;
        flex: 1 1;
        margin: 0px;
        border-radius: 0px;
    }


}

.edit-profile-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.edit-profile-screen .header-profile {
    font-size: 20px !important;
    font-family: 'Kanit', sans-serif !important;
}

.edit-profile-screen .profile-user {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
}

.edit-profile-screen .container-subheader {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.edit-profile-screen .container-subheader .subheader {
    padding: 10px;
    display: flex;
    align-items: center;
}

.edit-profile-screen .container-subheader .subheader .icon-header {
    font-size: 20px;
}

.edit-profile-screen .container-subheader .ui.divider {
    margin: 0px !important;
}

.edit-profile-screen .container-content {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}


.edit-profile-screen .container-content .divider-profile {
    border-left: solid 1px #EBEBEB;
    min-height: 250px;
    margin: 0px 10px;
}

.edit-profile-screen .container-content .container-content-input {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 10px 50px;
}

.edit-profile-screen .container-content .container-input {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: 5px 0px;
}

.edit-profile-screen .container-content .container-input .label-detail {
    min-width: 120px;
}

.edit-profile-screen .container-content .container-input .label-address {
    min-width: 75px;
}

.edit-profile-screen .container-content .container-input Input {
    flex: 1 1;
    width: 100%;
    border-radius: 20px;
    padding: 6px 15px;
    border-width: 1px;
    border-image: none;
    border-style: solid;
    font-family: 'Kanit', sans-serif !important;
    border-color: #979797;
    outline: none;
}

.edit-profile-screen .container-content .container-input .ui.input {
    flex: 1 1;
}

.edit-profile-screen .container-content .container-input .input-status {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.edit-profile-screen .container-button-action {
    justify-content: center;
    display: flex;
    align-items: center;
}

.edit-profile-screen .container-button-action .button-action {
    border-radius: 20px;
    padding: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    background-image: linear-gradient(#4786BE, #1967AD);
}

.edit-profile-screen .container-button-action .button-action span {
    color: #FFFFFF;
    font-family: 'Kanit', sans-serif;
    padding: 5px 10px;
    min-width: 120px;
}


@media screen and (max-width: 1058px) {
    .edit-profile-screen .container-content .divider-profile {
        display: none;
    }

    .edit-profile-screen .container-content .img-profile {
        margin: 10px 55px;
    }

    .edit-profile-screen .container-content .container-content-input {
        margin: 10px 55px;
    }

}




.dropdown-profile {
    display: flex;
    flex: 1 1;

}

.dropdown-profile .dropdown-pattern-profile {
    width: 100%;
    border-radius: 20px !important;
    padding: 8px 15px;
    border-width: 1px;
    border-image: none;
    border-style: solid;
    font-family: 'Kanit', sans-serif !important;
    border-color: #979797;
    outline: none;
}

.dropdown-profile .ui.fluid.dropdown {
    border-radius: 20px !important;
    border: solid 1px #979797;
}

.address-item span.active {
    color: green
}

.address-item span {
    color: #8e8e8e
}



button {
    font-family: 'Kanit', sans-serif !important;
}
.update-image-profile {
    display: flex;
}

.update-image-profile .img-profile-content {
    display: flex;
    position: relative;
}

.update-image-profile .img-profile {
    width: 200px;
    margin: 10px 50px;
    border-radius: 100px;
}

.update-image-profile .icon-uploadImg {
    background-color: #dedede;
    color: #4a4a4a;
    border: none;
    padding: 10px 6px 10px 8px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 60px;
    font-size: 28px;
    cursor: pointer;
    outline: none;
}

.update-image-profile .icon-cancelImg {
    background-color: #f15540;
    color: #FFF;
    border: none;
    padding: 6px 2px 6px 5px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 70px;
    font-size: 20px;
    cursor: pointer;
    outline: none;
}

.member-list-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.member-list-screen .search-box .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.member-list-screen .search-box .ui.selection.dropdown .ui.selection.dropdown>.dropdown.icon {
    top:1em;
}

.member-list-screen .header-text {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

.member-list-screen button {
    border-radius: 100px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

.member-list-screen .align-add-button {
    text-align: left !important;
}

.member-list-screen .add-button {
    border: none;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    align-items: center;
    box-shadow: 5px 5px 4px #c7c7c7;
    padding: 8px 10px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
}

.member-list-screen .filter-button {
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /*padding: 8px 15px;*/
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
}

.member-list-screen .filter-button i {
    color: #979797;
}

.member-list-screen .add-button i {
    margin-right: 10px;
}

.member-list-screen .search-box {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1 1;
    margin-right: 10px;
}

.member-list-screen .ui.selection.active.dropdown:hover, .member-list-screen .ui.selection.active.dropdown, .member-list-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.member-list-screen .ui.selection.active.dropdown {
    border-top-left-radius: 25px !important;
}

.member-list-screen .search-box i.dropdown.icon {
    border: none !important;
}

.member-list-screen .search-box .dropdown-search {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

.member-list-screen .search-box input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1 1;
}

.member-list-screen .search-box .search-content {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1 1;
}

.member-list-screen .search-box .search-content i.icon {
    height: auto;
    padding-right: 20px;
    color: #979797;
}

.filter-modal-screen .modal-filter {
    padding: 10px;
    margin: 0px;
}

.filter-modal-screen .modal-filter .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 20px !important;
    color: #00396D;
    font-weight: bold;
}

.filter-modal-screen .modal-filter .header-filter > div {
    display: flex;
    flex: 1 1;
    align-items: center;
}

.filter-modal-screen .modal-filter .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal-screen .modal-filter .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .filter-detail {
    margin-bottom: 10px;
}

.filter-modal-screen .modal-filter .filter-detail > div {
    margin-bottom: 5px;
    font-size: 14px !important;
}

.filter-modal-screen .modal-filter .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal-screen .modal-filter .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 14px !important;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 14px !important;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

.filter-modal-screen {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.member-list-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
}

.member-list-screen .header-table .space-block1 {
    width: 115px;
}

.member-list-screen .header-table .space-block1 .type-user-block {
    display: flex;
    /*flex: 1;*/
    margin-top: 5px;
}

.member-list-screen .header-table .type-symbol {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    margin-right: 5px;
}

.member-list-screen .header-table .column-tools {
    width: 80px;
    display: flex;
    align-items: center;
}

.member-list-screen .header-table .row-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.member-list-screen .header-table .row-content .block2-content {
    display: flex;
    flex-wrap: wrap;
    line-height: 25px;
}

.member-list-screen .header-table .row-content .space-content {
    flex: 1 1;
    min-width: 100px;
    display: flex;
}

.member-list-screen .block-member-card {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.member-list-screen .count-page {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

.member-list-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0px 5px;
}

.member-list-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
    /*border-color: #EB5757 !important;*/
}

.member-list-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.member-list-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.member-list-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.member-list-screen .count-page label {
    padding-right: 5px;

}

.member-list-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.member-list-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.member-list-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.member-list-screen .loading-block {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

@media screen and (max-width: 1047px) {
    .member-list-screen .search-box .ui.selection.dropdown {
        min-width: 0px !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .member-list-screen .align-add-button {
        text-align: right !important;
    }

    .member-list-screen .filter-button {
        min-width: 80px;
    }

    .member-list-screen .search-box .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .member-list-screen .align-add-button {
        text-align: right !important;
    }

    .member-list-screen .filter-button > label {
        display: none;
    }

    .member-list-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0px
    }

    .member-list-screen .filter-button > i {
        margin: 0px !important;
    }

    .member-list-screen .all-page {
        flex-direction: column;
    }

    .member-list-screen .header-table {
        font-size: 8px;
    }

    .member-list-screen .header-table .space-block1 {
        width: 90px;
    }

    .member-list-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .member-list-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .member-list-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .member-list-screen .header-table {
        padding: 8px !important;
        font-size: 10px;
    }
    .member-list-screen .search-box .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .member-list-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .member-list-screen .add-button {
        font-size: 10px;
    }
    .member-list-screen .search-box input {
        font-size: 10px;
    }
    .member-list-screen .header-text {
        font-size: 20px;
    }
    .member-list-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .member-list-screen {
        padding: 15px;
    }

    .member-list-screen .header-text {
        font-size: 15px;
        padding-bottom: 5px !important;
    }

    .member-list-screen .add-button {
        padding: 5px 12px !important;
        font-size: 8px !important;
        margin-bottom: 5px;
    }

    .member-list-screen .search-box {
        font-size: 8px !important;
    }

    .member-list-screen .search-box .dropdown-search {
        font-size: 8px;
    }

    .member-list-screen .search-box input {
        font-size: 8px
    }

    .member-list-screen .filter-button {
        padding: 5px 8px;
        font-size: 8px !important;

    }

    .member-list-screen .count-page {
        font-size: 8px;
    }

    .member-list-screen .count-page .ui.menu .item {
        font-size: 8px;
    }

    .member-list-screen .header-table {
        padding: 5px !important;
        font-size: 8px !important;
    }

    /*.member-list-screen .header-table .column-tools {*/
    /*    display: none !important;*/
    /*    width: 0px;*/
    /*}*/

    .member-list-screen .header-table .row-content .space-content {
        min-width: 100px !important;;
    }

    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0px !important;
        border-radius: 0px;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0px !important;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {
    .member-list-screen .header-text {
        font-size: 12px;
    }

    .member-list-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .member-list-screen .count-page > label {
        margin-bottom: 5px;
    }

    .member-list-screen .header-table .row-content .space-content {
        width: 80px !important;
    }

}

.card-member {
    /*background-color: brown;*/
    font-family: 'Kanit', sans-serif !important;

}

.card-member .container-border-card {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.card-member .members {
    display: flex;
    flex: 1 1;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.card-member .type-user-symbol {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    width: 30px;

}

.card-member .type-user-symbol .background-member-no {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.card-member .type-user-symbol .background-member-no .member-no {
    min-width: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;
}

.card-member .container-card {
    display: flex;
    padding: 10px 15px;
    flex: 1 1;
}

.card-member .container-detail-user {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    color: #4a4a4a;
}

.card-member .layout-pic-profile {
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-member .pic-profile {
    max-height: 40px;
    max-width: 40px;
    border-radius: 100px;
    border-color: #c4c4c4;
    border-width: 1px;
    margin-right: 10px;
}

.card-member .header-detail-member {
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
}

.card-member .container-text-detail {
    display: flex;
    flex-wrap: wrap;
    line-height: 25px;
}

.card-member .container-text-detail .space-block{
    flex: 1 1;
    min-width: 100px;
    font-size: 14px;
}

.card-member .text-detail-member {
    font-family: 'Kanit', sans-serif;
    font-size: 14px !important;
    color: #717171;
    padding-left: 5px;
}

.card-member .text-detail-member-address {
    display: flex;
    align-items: baseline;
    font-size: 14px !important;
    color: #717171;
}

.card-member .container-border-card .members .button-detail{
    padding-top: 10px;
    display: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #00396D;
}

.card-member .container-border-card .members .ui.dropdown .menu {
    top:20px !important;
}

.card-member .container-border-card .button-action{
    display: flex;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    flex: 1 1;
}

.card-member .button-action i {
    color: #FFFFFF;
    margin: 0px;
}

.card-member .container-border-card .button-action-modal{
   display: flex;
    flex: 1 1;
}

.card-member .container-border-card .button-action-modal i {
    color: #FFFFFF;
    margin: 0px;
}

.card-member .container-border-card .button-edit-member{
    flex: 1 1;
    background-color: rgb(242, 201, 76);
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 5px;
}

.card-member .container-border-card .button-info-member{
    flex: 1 1;
    background-color: #2185d0 !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
color: #ffffff;
    margin-bottom: 5px;
}



.card-member .container-border-card .layout-button-action{
    padding: 5px;
    display: flex;
flex-direction: column;
}

.card-member .container-border-card .layout-button-action i{
    font-size: 12px!important;

}

.card-member .members .container-detail-user .container-text-detail  div {
    display: flex;
    align-items: baseline;
    flex: 1 1;
}

.card-member .members .container-detail-user .container-text-detail  i{
   padding-right: 5px;
    font-size: 15px;
}

/*Ipad Screen*/
@media screen and (max-width: 850px){

    .card-member .layout-pic-profile {
        min-width: 62px;
    }

    .card-member .container-border-card .button-action {
        display: none;
    }

    .card-member .container-border-card .button-action-modal{
        display: none;
    }
    .card-member .container-border-card .button-info-member{
        display: none;
    }
    .card-member .container-border-card .members  .button-detail{
        display: flex;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #00396D;
        background-color: transparent;
    }

    .card-member .container-border-card .layout-button-action{
        width: 0px;
    }

    .card-member .header-detail-member {
        font-size: 10px !important;
        padding-bottom: 0px;
    }

    .card-member .text-detail-member {
        font-size: 10px !important;
    }

    .card-member .container-text-detail {
        line-height: 15px;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .card-member .header-detail-member {
        font-size: 10px !important;
    }

    .card-member .layout-pic-profile {
        min-width: 60px;
    }

    .card-member .text-detail-member {
        font-size: 8px !important;
    }


}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {

}



.approve-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.approve-screen .filter-alert-house{
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: red;
}

.approve-screen .header-text {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

.detail-table-block .subdetail-name  {
    margin-top: 5px;
    display: flex;
}

.detail-table-block .subdetail-name .text {
    font-size: 16px;
}

.approve-screen button {
    border-radius: 100px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

/*.approve-screen .align-add-button {*/
/*    text-align: left !important;*/
/*}*/

/*.approve-screen .add-button {*/
/*    border: none;*/
/*    background-image: linear-gradient(#4685BD, #1967AD);*/
/*    color: #FFFFFF;*/
/*    align-items: center;*/
/*    box-shadow: 5px 5px 4px #c7c7c7;*/
/*    padding: 8px 10px;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*}*/

.approve-screen .filter-button {
    position: relative;
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
    margin: 0;
}

.approve-screen .filter-button i {
    color: #979797;
}

.approve-screen .add-button i {
    margin-right: 10px;
}

.approve-screen .search-box {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1 1;
    margin-right: 10px;

}

.approve-screen .ui.selection.active.dropdown:hover, .approve-screen .ui.selection.active.dropdown, .approve-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.approve-screen .ui.selection.active.dropdown {
    border-top-left-radius: 25px !important;
}

.approve-screen .search-box i.dropdown.icon {
    border: none !important;
}

.approve-screen .search-box .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
}


.approve-screen .search-box .dropdown-search {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

.approve-screen .search-box input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1 1;

}

.approve-screen .search-box .search-content {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1 1;
}

.approve-screen .search-box .search-content i.icon {
    font-size: 12px;
    height: auto;
    padding: 0 20px;
    color: #979797;
}

.filter-modal-screen .modal-filter {
    padding: 10px;
    margin: 0px;
}

.filter-modal-screen .modal-filter .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 18px;
    color: var(--main-color);
    font-weight: bold;
}

.filter-modal-screen .modal-filter .header-filter > div {
    display: flex;
    flex: 1 1;
    align-items: center;
}

.filter-modal-screen .modal-filter .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal-screen .modal-filter .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .filter-detail {
    margin-bottom: 10px;
}

.filter-modal-screen .modal-filter .filter-detail > div {
    margin-bottom: 5px;
    font-size: 12px;
}

.filter-modal-screen .modal-filter .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal-screen .modal-filter .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 12px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

.filter-modal-screen {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.approve-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 50px 20px 20px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
    min-width: 345px;
}

.approve-screen .header-table .space-block1-number {
    width: 25px;
}

.approve-screen .header-table .type-symbol {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    margin-right: 5px;
}

.approve-screen .header-table .column-tools-approve {
    width: 41px;
    display: flex;
    align-items: center;
}

.approve-screen .header-table .row-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.approve-screen .header-table .row-content .block2-content {
    display: flex;
    flex-wrap: wrap;
}

.approve-screen .header-table .row-content .space-content {
    min-width: 250px;
    flex: 2 1;
    display: flex;
}

.approve-screen .block-member-card {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.approve-screen .block-member-card .none-data-container {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.approve-screen .count-page {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

.approve-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0px 5px;
}

.approve-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
    /*border-color: #EB5757 !important;*/
}

.approve-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.approve-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.approve-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.approve-screen .count-page label {
    padding-right: 5px;

}

.approve-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.approve-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.approve-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.approve-screen .loading-block {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}


@media screen and (max-width: 1047px) {
    .approve-screen .search-box .ui.selection.dropdown {
        min-width: 0 !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .approve-screen .filter-button {
        min-width: 80px;
    }

    .approve-screen .search-box .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .approve-screen .filter-button > label {
        display: none;
    }

    .approve-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0
    }

    .approve-screen .filter-button > i {
        margin: 0 !important;
    }

    .approve-screen .all-page {
        flex-direction: column;
    }


    .approve-screen .header-table .space-block1 {
        width: 90px;
    }

    .approve-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .approve-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .approve-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .approve-screen .header-table {
        font-size: 10px;
    }
    .approve-screen .search-box .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .approve-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .approve-screen .search-box input {
        font-size: 10px;
    }
    .approve-screen .header-text {
        font-size: 20px;
    }
    .approve-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .approve-screen {
        padding: 15px;
    }

    .approve-screen .header-text {
        font-size: 15px;
        padding-bottom: 5px !important;
    }

    .approve-screen .search-box {
        font-size: 8px !important;
    }

    .approve-screen .search-box .dropdown-search {
        font-size: 8px;
    }

    .approve-screen .search-box input {
        font-size: 8px
    }

    .approve-screen .filter-button {
        padding: 5px 8px;
        /*font-size: 8px !important;*/

    }

    .approve-screen .count-page {
        font-size: 8px;
    }

    .approve-screen .count-page .ui.menu .item {
        font-size: 8px;
    }


    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0 !important;
        border-radius: 0;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0 !important;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {
    /*.approve-screen .header-text {*/
    /*    font-size: 12px;*/
    /*}*/

    .approve-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .approve-screen .count-page > label {
        margin-bottom: 5px;
    }

    /*.approve-screen .header-table .row-content .space-content {*/
    /*    width: 80px !important;*/
    /*}*/

}

.card-house {
    /*background-color: brown;*/
    font-family: 'Kanit', sans-serif !important;

}

.card-house .container-border-card {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.card-house .members {
    display: flex;
    flex: 1 1;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.card-house .type-user-symbol {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    width: 30px;

}

.card-house .type-user-symbol .background-house-no {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.card-house .type-user-symbol .background-house-no .house-no {
    min-width: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;
}

.card-house .container-card {
    display: flex;
    padding: 5px;
    flex: 1 1;
}

.card-house .container-detail-house {
    display: flex;
    flex: 1 1;
    color: #4a4a4a;
    flex-wrap: wrap;
}

.card-house .container-detail-house .detail-table-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
    flex: 2 1;
}
.subdetail-table-block.housedetail{
    padding: 10px 0;
    display: flex;
}
.card-house .container-detail-house .detail-table-block .subdetail-table-block {
    display: flex;
}

.card-house .header-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #17436b;
    font-size: 20px;
    padding-top: 10px;
    /*padding: 10px 0*/
    /*padding: 10px 0*/
    /*font-weight: bold;*/
}

.card-house .container-text-detail {
    display: flex;
    flex-wrap: wrap;
}

.card-house .container-text-detail .space-block {
    flex: 1 1;
    min-width: 100px;
}

.card-house .text-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #717171;
}

.card-house .text-detail-member-address {
    display: flex;
    align-items: baseline;
    font-size: 12px !important;
    color: #9c9c9c;
}

.card-house .container-border-card .members .button-detail {
    padding-top: 10px;
    display: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #00396D;
}

.card-house .container-border-card .members .ui.dropdown .menu {
    top: 20px !important;
}

.card-house .container-border-card .button-action {
    display: flex;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 10px !important;
}

.card-house .button-action i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house .container-border-card .button-action-modal {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
}

.card-house .container-border-card .button-action-modal i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house .container-border-card .button-edit-member {
    flex: 1 1;
    background-color: rgb(242, 201, 76);
    margin-top: 5px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
}

.card-house .container-border-card .button-info-member {
    flex: 1 1;
    background-color: #2185d0 !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;

}


.card-house .container-border-card .layout-button-action {
    padding: 5px;
    display: flex;

}

.card-house .container-border-card .layout-button-action i {
    font-size: 12px !important;

}

.card-house .members .container-detail-user .container-text-detail div {
    display: flex;
    align-items: baseline;
    flex: 1 1;
}

.card-house .members .container-detail-user .container-text-detail i {
    padding-right: 5px;
    font-size: 12px;
}

/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .card-house .layout-pic-profile {
        min-width: 62px;
    }

    .card-house .container-border-card .button-action {
        display: none;
    }

    .card-house .container-border-card .members .button-detail {
        display: flex;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #00396D;
        background-color: transparent;
    }

    /*.card-house .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    /*.card-house .text-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    /*.card-house .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    .card-house .layout-pic-profile {
        min-width: 60px;
    }

    /*.card-house .text-detail-member {*/
    /*    font-size: 8px !important;*/
    /*}*/


}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {

}



.approve-data-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
}

.approve-data-screen .header-container {
    left: -28px;
    display: flex;
    align-items: center;
    margin: 10px 0 30px 0;
    position: relative;
    flex-direction: row;
}

.approve-data-screen .header-container .button-back {
    font-size: 40px;
    outline: none;
    border: none;
    cursor: pointer;
}

.approve-data-screen .header-container  i{
    background-color: #ffffff;
    border-radius: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
}

.approve-data-screen .header-container .header-taxt-breadcumb {
    display: flex;
    flex-direction: column;
}

.approve-data-screen .header-container .header-taxt-breadcumb .header-text {
    font-size: 24px;
    color:  var(--main-color);
    margin-bottom: 10px;
    font-weight: bold;

}


/*css ช่องผู้สำรวจ*/
.approve-data-screen .header-container .survey-container {
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px 0 0 10px;
    position: absolute;
    right: -60px;
    top: -10px;
}

.approve-data-screen .header-container .survey-container .survey-topic {
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    width: 70px;
}

.approve-data-screen .header-container .survey-container .survey-desc {
    font-size: 15px;
    color: #656565;
}

/*คลาสนี้ใช้แสดงตัวอย่าง Layout ใช้จริงจะเหลือแค่ diplay,flex*/
.approve-data-screen .border-grid {
    display: flex;
    flex: 1 1;
    border-radius: 10px;
    height: 200px;
    align-items: center;
    justify-content: center;
}

.approve-data-screen .block-button {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;

}

.approve-data-screen .block-button .approve-button {
    flex: 1 1;
    background-color: #1F82DA;
    margin-right: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.approve-data-screen .block-button .reject-button {
    flex: 1 1;
    background-color: #EB5757;
    margin-left: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.approve-data-screen .block-button .icon-button {
    font-size: 12px;
    border: solid 2px #F0F5F9;
    border-radius: 15px;
    margin-right: 5px;
    padding: 2px 0px 0px 3px;

}

@media screen and (max-width: 520px) {

    /*css ช่องผู้สำรวจ*/
    .approve-data-screen .header-container .survey-container {
        top:50px
    }
    .approve-data-screen .space-top {
        padding-top: 50px !important;
    }
}

/*css survey person*/
@media screen and (max-width: 991px) {
    .survey-person-detail {
        margin-top: 45px;
    }

}


.format-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 10px;
    font-family: 'Kanit', sans-serif;
    margin-top: 10px ;
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
}

.format-container .content-title {
    position: absolute;
    display: flex;
    align-items: center;
    top: -12px;
    left: -23px;
}

.format-container .content-title .icon-title {
    background-color: var(--main-color);
    color: #ffffff;
    padding: 11px 8px;
    border-radius: 21px;
    position: absolute;
    font-size: 20px;
}

.format-container .content-title .text-title {
    color: #ffffff;
    background-color: var(--main-color);
    padding: 4px 25px;
    border-radius: 12px;
    margin-left: 20px;
    font-size: 15px;
}

.format-container .content-detail {
    /*margin: 25px 15px 10px 15px;*/
    margin: 25px 20px 15px 20px;
    height: 100%;

}

.member-house-content {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    /*background-color: blue;*/
    border-radius: 10px;
    /*justify-content: center;*/
    margin-top: 20px;
}

.member-format {
    font-family: 'Kanit', sans-serif;
    border: solid 1px  var(--main2-color);
    border-radius: 10px;
    position: relative;
    padding: 25px 10px 10px 10px;
    /*margin: 0px 15px 30px 0px;*/
    margin-bottom: 30px;
    /*min-width: 250px;*/
}

.member-format .number-member {
    background-color:  var(--main2-color);
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 15px;
    color: #FFF;
    position: absolute;
    top: -20px;
    left: 44%;
}

.member-format .detail-member {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    /*color: #848484;*/
    /*min-width: 250px;*/
}

.member-format .detail-member .header-member {
    font-size: 15px;
    font-weight: bold;
    color: #535353;
}

.member-format .detail-member .subheader-member {
    font-size: 12px;
    color: #535353;
}

.member-format .detail-member .subdetail-member {
    display: flex;
    flex: 1 1;
    width: 100%;
}

.member-format .detail-member .subdetail-member .topic-member {
    flex: 1 1;
    text-align: end;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #808080;
}

.member-format .detail-member .subdetail-member .subtopic-member {
    flex: 1 1;
    font-size: 12px;
    color: #808080;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}



.quetion-content {
    display: flex;
    flex: 1 1;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    overflow: auto;
}

.quetion-content .topic-menu {
    font-family: 'Kanit', sans-serif !important;
}

.topic-group {
    background-color:  var(--main2-color);
    color: #FFF;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 10px;
}

.question-sub-topic {
    display: flex;
    padding: 10px 40px;
    border-bottom: solid 1px #d7d7d7;
}

.question-sub-topic .no {
    margin-right: 10px;
    font-size: 16px;
}

.question-sub-topic .content {
    flex: 1 1
}

.question-sub-topic .content .block {
    display: flex;
}

.question-sub-topic .content .block .title {
    width: 60px;
    color: #8c8c8c
}
.question-sub-topic .content .block .question{
    color:  var(--main2-color);
    flex: 1 1;
}

.question-answer {
    display: flex;
    padding: 10px 40px;
}

.question-answer .no {
    margin-right: 10px;
    font-size: 16px;
}

.question-answer .content {
    flex: 1 1
}

.question-answer .content .block {
    display: flex;
}

.question-answer .content .block .title {
    width: 60px;
    color: #8c8c8c
}
.question-answer .content .block .question{
    color:  var(--main-color);
    flex: 1 1;
}

.question-container {
    min-width: 500px;
    /*border-radius: 10px !important;*/
}

.ui.menu {
    font-family: 'Kanit', sans-serif !important;
}

.address-header {
    color: #013B70;
    font-weight: bold;
    font-size: 16px;
    line-height: 2em;
}

.address-topic {
    font-size: 14px;
    font-weight: bold;
    color: #656565;
}

.address-detail {
    font-size: 15px;
    color: #656565;
}


.line-height-row {
    margin-top: -28px !important;
}

/* iPad */
@media screen and (max-width: 767px) {

    .address-header {
        font-size: 16px;
        text-align: center;
    }

    .address-topic {
        font-size: 13px;
    }

    .address-detail {
        font-size: 13px;
    }
}

@media screen and (max-width: 490px) {

    .address-header {
        text-align: left;
    }

}

.location-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 10px;
    font-family: 'Kanit', sans-serif;
    margin-top: 10px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.map-container {
    position:relative;
    margin: 15px;
    /*margin-bottom: 100px;*/
    min-height: 234px;
    display: flex;
    justify-content: center;
}

.map-styled {
    width: 100% !important;
    height: 100% !important;
}

.btn-location {
    border-radius: 0px 0px 10px 10px !important;
    margin-right: 0px !important;
    font-size: 16px !important;
    background-color: var(--main-color) !important;
    color: #FFFFFF !important;
    cursor: none;
}

.edit-location {
    margin-top:10px;
    margin-right:10px;
    padding: 10px 20px;
    border: 2px solid var(--main-color);
    color: var(--main-color) !important;
    background-color: #FFF;
    border-radius: 3px !important;
    margin-left: 10px;
    cursor: pointer;
}

.edit-location i {
    color: var(--main-color)
}

/*iPad*/
@media screen and (max-width: 768px) {
    .location-container {
        margin-top: 25px !important;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {
    .map-styled {
        width: 92% !important;
    }

    .btn-location {
        font-size: 14px !important;
    }
}

.approve-data {
    overflow-x: scroll;
}
.text-header {
    font-size: 14px;
    font-weight: bold;
}

.tr {
    line-height: 15px;
}

.tr:nth-child(odd) {
    background: #EBEBEB !important;
}

.cell-small {
    min-width: 70px;
}

.cell-medium {
    min-width: 135px;
}

.cell-large {
    min-width: 185px;
}

.pd-layout {
    /*padding: 40px 60px 40px 60px !important;*/
}

.radius {
    border-radius: 10px !important;
    border: 1px solid #C4C4C4 !important;
}

.left-cell-radius {
    border-radius: 10px 0px 0px 0px !important;
}

.right-cell-radius {
    border-radius: 0px 10px 0px 0px !important;
}

@media screen and (max-width: 850px) {

    .pd-layout {
        padding: 15px !important;
    }

    .text-header {
        font-size: 14px;
    }

    .cell-small {
        min-width: 62px;
    }

    .cell-medium {
        min-width: 120px;
    }

    .cell-large {
        min-width: 185px;
    }
}

.survey-data-screen {

}

 /*css ช่องผู้สำรวจ*/
.survey-data-screen .survey-container {
}

.survey-data-screen .survey-container .survey-topic {
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    width: 80px;
}

.survey-data-screen .survey-container .survey-desc {
    font-size: 15px;
    color: #656565;
}

.setting-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.setting-screen .button-setting-app-container {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    border: none;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    border-radius: 10px;
    margin-right: 15px;
    position: relative;
    z-index: 0;
    width: 235px;
    margin-bottom: 15px;
    cursor: pointer;
}

.setting-screen .button-setting-app-container::after {
    content: "";
    background-image: url(/static/media/silk_pattern.b8c7f2e9.png);
    background-size: 80px 80px;
    opacity: 0.04;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -99;
}

.setting-screen .button-setting-app-container .icon-setting-app {
    padding: 8px 4px 8px 7px;
    border-radius: 20px;
    background-image: linear-gradient(#f5f5f5, #cccccc);
    margin-right: 15px;
    color: var(--main-color);
    font-size: 24px;
}

.setting-screen .button-setting-app-container .header-setting-container {
    color: #FFF;
}

.setting-screen .button-setting-app-container .header-setting-container .header-setting-text {
    font-size: 25px;
    margin-bottom: 5px;
}

.setting-screen .button-setting-app-container .header-setting-container .subheader-setting-text {
    font-size: 18px;
}


.header-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: transparent;
    position: relative;
    border-radius: 20px;
    font-family: 'Kanit', sans-serif;
    margin-top: 10px ;
}

.header-container .content-title {
    position: absolute;
    display: flex;
    align-items: center;
    top: -12px;
    left: -23px;
}

.header-container .content-title .icon-title {
    background-color: var(--main-color);
    color: #ffffff;
    padding: 11px 8px;
    border-radius: 21px;
    position: absolute;
    font-size: 20px;
}

.header-container .content-title .text-title {
    color: #ffffff;
    background-color: var(--main-color);
    padding: 4px 25px;
    border-radius: 12px;
    margin-left: 20px;
    font-size: 15px;
}

.header-container .content-detail {
    /*margin: 25px 15px 10px 15px;*/
    margin: 25px 20px 15px 20px;
}

.setting-app-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
}

.setting-app-screen .header-container {
    left: -28px;
    display: flex;
    align-items: center;
    margin: 10px 0 40px 0;
    position: relative;
    flex-direction: row;
}

.setting-app-screen .header-container .button-back {
    font-size: 40px;
    outline: none;
    border: none;
    cursor: pointer;
}

.setting-app-screen .header-container  i{
    background-color: #ffffff;
    border-radius: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
}

.setting-app-screen .header-container .header-taxt-breadcumb {
    display: flex;
    flex-direction: column;
}

.setting-app-screen .header-container .header-taxt-breadcumb .header-text {
    font-size: 24px;
    color: var(--main-color);
    margin-bottom: 10px;
    font-weight: bold;

}

.setting-app-screen i.icon {
    padding-left: 3px;
}



.setting-table-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
}

.setting-table-screen i.icon {
    padding-left: 3px;
}

.setting-table-screen .table-name-header {
    width: 100%;
}

.setting-table-screen .detail-table {
    flex: 1 1;
}

.setting-table-screen .search-container {
    display: flex;
    border: solid 1px #d0d0d0;
    padding: 5px 10px;
    border-radius: 10px;
    color: #9f9f9f;
    background-color: #FFF;
}

.setting-table-screen .search-container > input {
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    flex: 1 1;
}

.setting-table-screen .add-input {
    display: flex;
    align-items: center;
}

.setting-table-screen .add-input > input {
    width: 100%;
    padding: 8px 10px;
    border: solid 1px #d0d0d0;
    border-radius: 10px;
    outline: none;
}

.setting-table-screen .add-input .add-button {
    padding: 7px 8px;
    border: none;
    border-radius: 10px;
    background-color: #2A85D7;
    color: #FFFF;
    min-width: 155px;
    margin-left: 10px;
    outline: none;
    cursor: pointer;
}


.setting-table-screen .block-table {
    width: 100%;
    display: flex;
    align-items: center;
}

.setting-table-screen .block-table .edit-button {
    border-radius: 15px;
    border: none;
    padding: 7px 5px;
    text-align: center;
    background-color: #F2C94C;
    margin: 0 5px;
    color: #FFF;
    outline: none;
    cursor: pointer;
}

.setting-table-screen .block-table .edit-button:active {
    background-color: #8b752c;
}

.setting-table-screen .block-table .del-button {
    border-radius: 15px;
    border: none;
    padding: 7px 5px;
    text-align: center;
    background-color: #EB5757;
    margin: 0 5px;
    color: #FFF;
    outline: none;
    cursor: pointer;
}

.setting-table-screen .block-table .edit-container {
    display: flex;
    flex: 1 1;
    align-items: center;
    border: solid 1px #d0d0d0;
    border-radius: 10px;
}

.setting-table-screen .block-table .edit-container > input {
    width: 100%;
    padding: 8px 10px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.setting-table-screen .block-table .edit-container > a {
    padding-right: 5px;
    color: #9f9f9f !important;
    cursor: pointer;
}

.setting-table-screen .block-table .edit-container > i.icon {
    color: #9f9f9f !important;
    font-size: 12px;
}

.setting-table-screen .block-table .save-button {
    padding: 7px 8px;
    border: none;
    border-radius: 10px;
    background-color: #2A85D7;
    color: #FFFF;
    min-width: 100px;
    margin-left: 10px;
    outline: none;
}

.setting-table-screen .block-table .close-button {
    border: none;
    color: #2A85D7;
    margin-left: 10px;
    outline: none;
    cursor: pointer;
}

.map-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    position: relative;
}

.map-screen .backdrop {
    display: flex;
    flex-direction: column;
    color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: 99;
    align-items: center;
    justify-content: center;
}

.map-screen .address-no {
    width: 70px;
    position: relative;
    z-index: 99;
    left: -35px;
    top: -60px;
    text-align: center;
}

.map-screen .backdrop h1 {
    margin: 0 !important;
    margin-top: -20px;
}

.map-screen .backdrop i.disabled.icon {
    font-size: 130px;
    color: #FFF !important;
    opacity: 1 !important;
}

.map-screen .header-content {
    width: 160px;
    position: absolute;
    z-index: 1;
    left: 0;
    background-color: #ffffff;
    padding: 15px 10px 10px;
    border-radius: 0 10px 10px 0;
    font-family: 'Kanit', sans-serif;
}

.map-screen .header-content .header-text {
    color: #595959;
    font-size: 24px;
    font-weight: 700;
}

.map-screen .header-content .subheader-text {
    color: #808080;
}

.map-screen .map-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.map-screen .icon-marker {
    cursor: pointer;
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 80px;
    right: 40px;
}

.map-screen .icon-cluster {
    cursor: pointer;
    box-shadow: 0 0 14px #4b494933;
    width: 80px;
    height: 80px;
    border: solid 6px #FFF;
    border-radius: 40px;
    background: #0d4e89;
    font-size: 150%;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-screen .map-content .filter-content {
    position: absolute;
    top: 20px;
    right: 20px;
}

.map-screen .map-content .filter-house {
    height: 190px;
    background-color: #FFF;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: 0.5s;
}

.map-screen .map-content .filter-house.select-filter {
    height: 270px;
}

.map-screen .map-content .filter-house.hide {
    height: 49px;
    overflow: hidden;
}

.map-screen .map-content .filter-house > .topic {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    margin-top: -3px;
}

.map-screen .map-content .filter-house > .topic > .map {
    color: var(--main-color);
    opacity: 1 !important;
}

.map-screen .map-content .filter-house > .topic > .filter {
    color: var(--main-color);
    opacity: 1 !important;
}

.map-screen .map-content .filter-house > .topic > .caret.down {
    color: #1967AD;
    opacity: 1 !important;
}

.map-screen .map-content .filter-house > .topic > .window.minimize {
    color: #1967AD;
    opacity: 1 !important;
    margin-bottom: 10px;
}

.map-screen .map-content .filter-house > .topic > h4 {
    color: var(--main2-color);
    margin: 0 !important;
    padding-top: 5px;
    padding-right: 80px;
    font-family: 'Kanit', sans-serif;
}

.map-screen .map-content .filter-house > .search.dropdown {
    margin: 5px 0;
    border-radius: 10px;
}

.map-screen .map-content .icon-checkcox {
    margin-top: -10px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

.map-screen .map-content .filter-house > .ui.checkbox {
    display: block;
    margin-bottom: 15px;
}

.map-screen .map-content .filter-house .switch-icon {
    position: absolute;
    right: 10px;
}

.map-screen .type-map img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    border: solid 5px #FFF;
    cursor: pointer;
}

.map-screen .type-map {
    float: right;
}

.map-screen button.gm-control-active.gm-fullscreen-control {
    display: none !important;
}

@media screen and (max-width: 425px) {
    .map-screen .map-content .filter-content {
        top: 90px;
    }
}

.cluster-list {
    cursor: pointer;
    padding:10px 10px;
    display: flex;
    align-items: center;
    border: solid 1px #f1f1f1;
    border-radius: 10px;
    box-shadow: 0 0 5px #f1f1f1;
    transition: all 0.5s
}

.cluster-list:hover{
    box-shadow: 0 0 10px #e0e0e0;
}

.cluster-list img {
    width: 50px;
    height: 50px;
    margin-right:10px;
}

.cluster-list .detail-house_no{
    color: #868686;
}


.detail-maker-house {
    background-color: #EDEDED;
    padding: 25px 30px;
    height: 100%;
    overflow: scroll;
}

.rodal-close {
    display: none;
}

.rodal-dialog-overide {
    position: absolute;
    width: 75%;
    right: 0;
    top: 0;
    bottom: 0;
    left: unset;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    padding: 0;
}


.modal-slide {
    width: 100%;
    height: 100%;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
}

.detail-maker-house .button-back {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
}

.detail-maker-house .button-back .button-back-color {
    color: #01396c ;
}

.detail-maker-house .approve-subcontant-container {
    padding-bottom: 25px;
    display:flex ;
}

.detail-maker-house .subcontant-container {
    padding-bottom: 25px;
    height: 100%;
}

.detail-maker-house .button-subcontant-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
}

.detail-maker-house .button-subcontant-container .button-next-page {
    background-color: #1967AD;
    margin-right: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.detail-maker-house .survey-person-detail {
    padding-bottom: 20px;
}

.status-house {
    display: flex;
    height: 100%;
    padding-top: 10px;
    /*align-items: center;*/
}

.status-house .line-status {
    border: solid 1px #D4D4D4;
    height: 200px;
    margin-left: 17px;
    margin-bottom: 15px;
}

.status-house .status-house-content {
    display: flex;
    position: absolute;
    flex-direction: column;
}

.status-house .status-house-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.status-house .status-house-container .icon-status {
    background-color: #D4D4D4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .icon-status-now {
    background-color: #8DC75F;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .icon-status-error {
    background-color: #ba1002;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .icon-status-warning {
    background-color: #f5bf02;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .text-status-now {
    color: #71AA43;
}

.status-house .status-house-container .text-status-error {
    color: #ba1002;
}

.status-house .status-house-container .text-status-warning {
    color: #f5bf02;
}

.listhousehold-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.listhousehold-screen .filter-alert-house{
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: red;
}

/*CSS Header Text*/
.listhousehold-screen .header-text-style {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

/*CSS Search Blog*/
.listhousehold-screen .search-container {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1 1;
    /*overflow: hidden;*/
    margin-right: 10px;
}


/*Search Deropdown*/
.listhousehold-screen .ui.selection.active.dropdown:hover, .listhousehold-screen .ui.selection.active.dropdown, .listhousehold-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.listhousehold-screen .ui.selection.dropdown {
    border-right: solid 2px #c4c4c4;
    border-top: none;
    border-bottom: none;
    border-left: none;
}

.listhousehold-screen .search-container i.dropdown.icon {
    border: none !important;
}

.listhousehold-screen .search-container .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 50px 0 0 50px;
}

.listhousehold-screen .search-container .search-dropdown {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

/*Search Input*/
.listhousehold-screen .search-container .search-input {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1 1;
}

.listhousehold-screen .search-container  input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1 1;
}

.listhousehold-screen .search-container .search-input i.icon {
    font-size: 12px;
    height: auto;
    padding:0 20px;
    color: #979797;
}

.listhousehold-screen button {
    border-radius: 50px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

/*Filter button*/
.listhousehold-screen .filter-button {
    position: relative;
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    margin: 0 !important;
    color: #616161;
}

.listhousehold-screen .filter-button i {
    color: #979797;
}

.filter-modal {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.filter-modal .filter-modal-screen{
    padding: 10px;
    margin: 0px;
}

.filter-modal .filter-modal-screen .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 18px;
    color: #00396D;
    font-weight: bold;
}

.filter-modal .filter-modal-screen .header-filter > div {
    display: flex;
    flex: 1 1;
    align-items: center;
}

.filter-modal .filter-modal-screen .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal .filter-modal-screen .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal .filter-modal-screen .filter-detail {
    margin-bottom: 10px;
}

.filter-modal .filter-modal-screen .filter-detail > div {
    margin-bottom: 5px;
    font-size: 14px;
}

.filter-modal .filter-modal-screen .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal .filter-modal-screen .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
}

.filter-modal .filter-modal-screen .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 14px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

/*Table Household*/
.listhousehold-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 50px 20px 20px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
    min-width: 345px;
}

.listhousehold-screen .header-table .number-content {
    width: 25px;
}

.listhousehold-screen .header-table .topic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.listhousehold-screen .header-table .topic-content .topic-detail-content {
    display: flex;
    flex-wrap: wrap;
}

.listhousehold-screen .header-table .topic-content .space-content {
    flex: 2 1;
    min-width: 250px;
    display: flex;
}

/*Show detail household */
.listhousehold-screen .blog-member-card {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.listhousehold-screen .blog-member-card .none-data-container {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

/*Number of page*/
.listhousehold-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.listhousehold-screen .count-page {
    font-family: 'Kanit', sans-serif !important;
    font-size: 10px !important;
}

.listhousehold-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.listhousehold-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.listhousehold-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif !important;
    font-size: 10px !important;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0 5px;
}

.listhousehold-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
}

.listhousehold-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.listhousehold-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.listhousehold-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.listhousehold-screen .count-page label {
    padding-right: 5px;

}


@media screen and (max-width: 1047px) {
    .listhousehold-screen .search-container .ui.selection.dropdown {
        min-width: 0 !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .listhousehold-screen .filter-button {
        min-width: 80px;
    }

    .listhousehold-screen .search-container .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .listhousehold-screen .filter-button > label {
        display: none;
    }

    .listhousehold-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0
    }

    .listhousehold-screen .filter-button > i {
        margin: 0 !important;
    }

    .listhousehold-screen .all-page {
        flex-direction: column;
    }


    .listhousehold-screen .header-table .space-block1 {
        width: 90px;
    }

    .listhousehold-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .listhousehold-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .listhousehold-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .listhousehold-screen .header-table {
        font-size: 12px;
    }
    .listhousehold-screen .search-container .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .listhousehold-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .listhousehold-screen .search-container input {
        font-size: 10px;
    }
    .listhousehold-screen .header-text-style {
        font-size: 20px;
    }
    .listhousehold-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .listhousehold-screen {
        padding: 15px;
        margin-right: 15px;
    }

    .listhousehold-screen .header-text-style {
        font-size: 13px;
        padding-bottom: 5px !important;
    }

    .listhousehold-screen .search-container {
        font-size: 10px !important;
    }

    .listhousehold-screen .search-container .dropdown-search {
        font-size: 10px;
    }

    .listhousehold-screen .search-container input {
        font-size: 10px
    }

    .listhousehold-screen .filter-button {
        padding: 5px 8px;

    }

    .listhousehold-screen .count-page {
        font-size: 8px;
    }

    .listhousehold-screen .count-page .ui.menu .item {
        font-size: 8px;
    }


    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0 !important;
        border-radius: 0;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0 !important;
    }

    .listhousehold-screen .search-container .search-input i.icon {
        padding: 0 15px 0 0;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {


    .listhousehold-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .listhousehold-screen .count-page > label {
        margin-bottom: 5px;
    }


}


.card-house-list-list {
    /*background-color: brown;*/
    font-family: 'Kanit', sans-serif !important;

}

.card-house-list .container-border-card {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.card-house-list .members {
    display: flex;
    flex: 1 1;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.card-house-list .type-user-symbol {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    width: 30px;

}

.card-house-list .type-user-symbol .background-house-no {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.card-house-list .type-user-symbol .background-house-no .house-no {
    min-width: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;
}

.card-house-list .container-card {
    display: flex;
    padding: 5px;
    flex: 1 1;
}

.card-house-list .container-detail-house {
    display: flex;
    flex: 1 1;
    color: #4a4a4a;
    flex-wrap: wrap;
}

.card-house-list .container-detail-house .detail-table-block {
    display: flex;
    flex-direction: column;
    flex: 2 1;
    justify-content: center;
    min-width: 250px;
}
.subdetail-table-block .housedetail{
    padding: 10px 0;
    display: flex;
}
.card-house-list .container-detail-house .detail-table-block .subdetail-table-block {
    display: flex;
}

.card-house-list .header-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #17436b;
    font-size: 20px;
    padding-top: 10px;
    /*padding: 10px 0*/
    /*padding: 10px 0*/
    /*font-weight: bold;*/
}

.card-house-list .container-text-detail {
    display: flex;
    flex-wrap: wrap;
}

.card-house-list .container-text-detail .space-block {
    flex: 1 1;
    min-width: 100px;
}

.card-house-list .text-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #717171;
    padding-left: 8px;
}

.card-house-list .text-detail-member-success {
    font-family: 'Kanit', sans-serif;
    color: #6fa542;
    padding-left: 8px;
}

.card-house-list .text-detail-member-error {
    font-family: 'Kanit', sans-serif;
    color: #ba1002;
    padding-left: 8px;
}

.card-house-list .text-detail-member-warning {
    font-family: 'Kanit', sans-serif;
    color: #d9a909;
    padding-left: 8px;
}

.card-house-list .text-detail-member-address {
    display: flex;
    align-items: baseline;
    font-size: 12px !important;
    color: #9c9c9c;
}

.card-house-list .container-border-card .members .button-detail {
    padding-top: 10px;
    display: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #00396D;
}

.card-house-list .container-border-card .members .ui.dropdown .menu {
    top: 20px !important;
}

.card-house-list .container-border-card .button-action {
    display: flex;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 10px !important;
}

.card-house-list .button-action i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house-list .container-border-card .button-action-modal {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
}

.card-house-list .container-border-card .button-action-modal i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house-list .container-border-card .button-edit-member {
    flex: 1 1;
    background-color: rgb(242, 201, 76);
    margin-top: 5px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
}

.card-house-list .container-border-card .button-info-member {
    flex: 1 1;
    background-color: #2185d0 !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;

}


.card-house-list .container-border-card .layout-button-action {
    padding: 5px;
    display: flex;

}

.card-house-list .container-border-card .layout-button-action i {
    font-size: 12px !important;

}

.card-house-list .members .container-detail-user .container-text-detail div {
    display: flex;
    align-items: baseline;
    flex: 1 1;
}

.card-house-list .members .container-detail-user .container-text-detail i {
    padding-right: 5px;
    font-size: 12px;
}



/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .card-house-list .layout-pic-profile {
        min-width: 62px;
    }

    .card-house-list .container-border-card .button-action {
        display: none;
    }

    .card-house-list .container-border-card .members .button-detail {
        display: flex;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #00396D;
        background-color: transparent;
    }

    /*.card-house-list .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    /*.card-house-list .text-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    /*.card-house-list .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    .card-house-list .layout-pic-profile {
        min-width: 60px;
    }

    /*.card-house-list .text-detail-member {*/
    /*    font-size: 8px !important;*/
    /*}*/


}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {

}




.show-detail-household {
    background-color: #EDEDED;
    padding: 25px 30px;
    height: 100%;
    overflow: scroll;
}

.rodal-close {
    display: none;
}

.rodal-dialog-overide {
    position: absolute;
    width: 75%;
    right: 0;
    top: 0;
    bottom: 0;
    left: unset;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    padding: 0;
}


.modal-slide {
    width: 100%;
    height: 100%;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
}

.show-detail-household .button-back {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
}

.show-detail-household .button-back .button-back-color {
    color: #01396c ;
}

.show-detail-household .approve-subcontant-container {
    padding-bottom: 25px;
    display:flex ;
}

.show-detail-household .subcontant-container {
    padding-bottom: 25px;
    height: 100%;
}

.show-detail-household .button-subcontant-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
}

.show-detail-household .button-subcontant-container .button-next-page {
    background-color: #1967AD;
    margin-right: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.show-detail-household .survey-person-detail {
    padding-bottom: 20px;
}

.history-user-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

/*CSS Header Text*/
.history-user-screen .header-text-style {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

/*CSS Search Blog*/
.history-user-screen .search-container {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1 1;
    /*overflow: hidden;*/
    margin-right: 10px;
}


/*Search Deropdown*/
.history-user-screen .ui.selection.active.dropdown:hover, .history-user-screen .ui.selection.active.dropdown, .history-user-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.history-user-screen .ui.selection.dropdown {
    border-right: solid 2px #c4c4c4;
    border-top: none;
    border-bottom: none;
    border-left: none;
}

.history-user-screen .search-container i.dropdown.icon {
    border: none !important;
}

.history-user-screen .search-container .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 50px 0 0 50px;
}

.history-user-screen .search-container .search-dropdown {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

/*Search Input*/
.history-user-screen .search-container .search-input {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1 1;
}

.history-user-screen .search-container  input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1 1;
}

.history-user-screen .search-container .search-input i.icon {
    font-size: 12px;
    height: auto;
    padding:0 20px;
    color: #979797;
}

.history-user-screen button {
    border-radius: 50px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

/*Filter button*/
.history-user-screen .filter-button {
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
}

.history-user-screen .filter-button i {
    color: #979797;
}

.filter-modal {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.filter-modal .filter-modal-screen{
    padding: 10px;
    margin: 0px;
}

.filter-modal .filter-modal-screen .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 18px;
    color: #00396D;
    font-weight: bold;
}

.filter-modal .filter-modal-screen .header-filter > div {
    display: flex;
    flex: 1 1;
    align-items: center;
}

.filter-modal .filter-modal-screen .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal .filter-modal-screen .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal .filter-modal-screen .filter-detail {
    margin-bottom: 10px;
}

.filter-modal .filter-modal-screen .filter-detail > div {
    margin-bottom: 5px;
    font-size: 14px;
}

.filter-modal .filter-modal-screen .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal .filter-modal-screen .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
}

.filter-modal .filter-modal-screen .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 14px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

/*Table Household*/
.history-user-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0 0;
    padding: 20px 50px 20px 20px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
    min-width: 345px;
}

.history-user-screen .header-table .number-content {
    width: 25px;
}

.history-user-screen .header-table .topic-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.history-user-screen .header-table .topic-content .topic-detail-content {
    display: flex;
    flex-wrap: wrap;
}

.history-user-screen .header-table .topic-content .space-content {
    flex: 2 1;
    min-width: 250px;
    display: flex;
}

/*Show detail household */
.history-user-screen .blog-member-card {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.history-user-screen .blog-member-card .none-data-container {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

/*Number of page*/
.history-user-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.history-user-screen .count-page {
    font-family: 'Kanit', sans-serif !important;
    font-size: 10px !important;
}

.history-user-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.history-user-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.history-user-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif !important;
    font-size: 10px !important;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0 5px;
}

.history-user-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
}

.history-user-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.history-user-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.history-user-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.history-user-screen .count-page label {
    padding-right: 5px;

}

@media screen and (max-width: 1047px) {
    .history-user-screen .search-container .ui.selection.dropdown {
        min-width: 0 !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .history-user-screen .filter-button {
        min-width: 80px;
    }

    .history-user-screen .search-container .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .history-user-screen .filter-button > label {
        display: none;
    }

    .history-user-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0
    }

    .history-user-screen .filter-button > i {
        margin: 0 !important;
    }

    .history-user-screen .all-page {
        flex-direction: column;
    }


    .history-user-screen .header-table .space-block1 {
        width: 90px;
    }

    .history-user-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .history-user-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .history-user-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .history-user-screen .header-table {
        font-size: 12px;
    }
    .history-user-screen .search-container .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .history-user-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .history-user-screen .search-container input {
        font-size: 10px;
    }
    .history-user-screen .header-text-style {
        font-size: 20px;
    }
    .history-user-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .history-user-screen {
        padding: 15px;
        margin-right: 15px;
    }

    .history-user-screen .header-text-style {
        font-size: 13px;
        padding-bottom: 5px !important;
    }

    .history-user-screen .search-container {
        font-size: 10px !important;
    }

    .history-user-screen .search-container .dropdown-search {
        font-size: 10px;
    }

    .history-user-screen .search-container input {
        font-size: 10px
    }

    .history-user-screen .filter-button {
        padding: 5px 8px;

    }

    .history-user-screen .count-page {
        font-size: 8px;
    }

    .history-user-screen .count-page .ui.menu .item {
        font-size: 8px;
    }


    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0 !important;
        border-radius: 0;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0 !important;
    }

    .history-user-screen .search-container .search-input i.icon {
        padding: 0 15px 0 0;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {


    .history-user-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .history-user-screen .count-page > label {
        margin-bottom: 5px;
    }


}


.card-house-history {
    /*background-color: brown;*/
    font-family: 'Kanit', sans-serif !important;

}

.card-house-history .container-border-card {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.card-house-history .members {
    display: flex;
    flex: 1 1;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.card-house-history .type-user-symbol {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    width: 30px;

}

.card-house-history .type-user-symbol .background-house-no {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.card-house-history .type-user-symbol .background-house-no .house-no {
    min-width: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;
}

.card-house-history .container-card {
    display: flex;
    padding: 5px;
    flex: 1 1;
    min-width: 305px;
}

.card-house-history .container-detail-house {
    display: flex;
    flex: 1 1;
    color: #4a4a4a;
    flex-wrap: wrap;
}

.card-house-history .container-detail-house .detail-table-block {
    display: flex;
    flex-direction: column;
    flex: 2 1;
    justify-content: center;
    min-width: 250px;
}
.subdetail-table-block .housedetail{
    padding: 10px 0;
    display: flex;
}
.card-house-history .container-detail-house .detail-table-block .subdetail-table-block {
    display: flex;
}

.card-house-history .header-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #17436b;
    font-size: 20px;
    padding-top: 10px;
    /*padding: 10px 0*/
    /*padding: 10px 0*/
    /*font-weight: bold;*/
}

.card-house-history .container-text-detail {
    display: flex;
    flex-wrap: wrap;
}

.card-house-history .container-text-detail .space-block {
    flex: 1 1;
    min-width: 100px;
}

.card-house-history .status-symbol-success {
    padding: 9px;
    background-color: #6fa542;
    border-radius: 20px;
}

.card-house-history .status-symbol-error{
    padding: 9px;
    background-color: #ba1002;
    border-radius: 20px;
}

.card-house-history .text-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #717171;
    padding-left: 8px;
}

.card-house-history .text-detail-member-success {
    font-family: 'Kanit', sans-serif;
    color: #6fa542;
    padding-left: 8px;
}

.card-house-history .text-detail-member-error {
    font-family: 'Kanit', sans-serif;
    color: #ba1002;
    padding-left: 8px;
}

.card-house-history .text-detail-member-warning {
    font-family: 'Kanit', sans-serif;
    color: #d9a909;
    padding-left: 8px;
}

.card-house-history .text-detail-member .status-symbol-success {
    padding: 9px;
    background-color: #6fa542;
    border-radius: 20px;
}

.card-house-history .text-detail-member .status-symbol-error{
    padding: 9px;
    background-color: #ba1002;
    border-radius: 20px;
}

.card-house-history .text-detail-member-address {
    display: flex;
    align-items: baseline;
    font-size: 12px !important;
    color: #9c9c9c;
}

.card-house-history .container-border-card .members .button-detail {
    padding-top: 10px;
    display: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #00396D;
}

.card-house-history .container-border-card .members .ui.dropdown .menu {
    top: 20px !important;
}

.card-house-history .container-border-card .button-action {
    display: flex;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 10px !important;
}

.card-house-history .button-action i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house-history .container-border-card .button-action-modal {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
}

.card-house-history .container-border-card .button-action-modal i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house-history .container-border-card .button-edit-member {
    flex: 1 1;
    background-color: rgb(242, 201, 76);
    margin-top: 5px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
}

.card-house-history .container-border-card .button-info-member {
    flex: 1 1;
    background-color: #2185d0 !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;

}


.card-house-history .container-border-card .layout-button-action {
    padding: 5px;
    display: flex;

}

.card-house-history .container-border-card .layout-button-action i {
    font-size: 12px !important;

}

.card-house-history .members .container-detail-user .container-text-detail div {
    display: flex;
    align-items: baseline;
    flex: 1 1;
}

.card-house-history .members .container-detail-user .container-text-detail i {
    padding-right: 5px;
    font-size: 12px;
}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .card-house-history .layout-pic-profile {
        min-width: 62px;
    }

    .card-house-history .container-border-card .button-action {
        display: none;
    }

    .card-house-history .container-border-card .members .button-detail {
        display: flex;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #00396D;
        background-color: transparent;
    }

    /*.card-house-history .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    /*.card-house-history .text-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    /*.card-house-history .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    .card-house-history .layout-pic-profile {
        min-width: 60px;
    }

    /*.card-house-history .text-detail-member {*/
    /*    font-size: 8px !important;*/
    /*}*/


}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {

}



* {
    font-family: "Kanit", sans-serif;
}
.main-layout-container {
    background-color: #EBEBEB;
}

@media only screen and (max-width: 767px) {
    .icon-male {
        font-size: 5rem !important;
        top: -35px;
    }
    .sign-amount p {
        font-size: 1rem;
    }
    .sign-sex p {
        font-size: 1rem;
    }

    .header-dash{
        margin-bottom: 0px!important;
    }
    .header-population-text{
        margin-bottom: 0px!important;
    }
}




.main-prog-container,
.main-progOnce-container {
  background-color: white;
  border-radius: 10px;
  padding: 15px 10px 0px;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  transition-duration: 1s;
  height: 100%;
}
.progbar-container {
  display: flex;
  /* background-color: white; */
  padding: 5px 5px 0px;
  /* flex-direction: column; */
  /* padding: 0px 10px 0px 0px; */
  /* justify-content: center; */
}
.desc-bar {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}
.desc-bar p {
  padding: 0px;
  margin: 0px;
}
.main-progOnce-container .ui.progress .bar{
  min-width: 0 !important;
}
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--main2-color);
}
.main-prog-container .header .title-icon{
  margin-right: 5px;
}
.cont-progress {
  margin-top: 5px;
}
.prog-top-bar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.main-progOnce-container .ui.progress {
  background: rgba(233,74,95,.8) !important;
}
.ui.green.progress .bar {
  background-color: rgba(99,210,162,1)!important;
}

.graph-container {
  display: flex;
  justify-content: space-between;
  height: 315px;
  transition-duration: 1s;
}
.left-graph {
  background-color: #2e465b;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: space-between;
  flex: 1 0 auto;
  padding: 10px 10px;
}
.left-graph p {
  color: white;
}
.color-icon {
  color: white;
}
.all-propulate {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.all-propulate p {
  margin: 0px;
}
.propulate-head {
  display: flex;
  align-items: center;
}
.propulate-head > p {
  margin-left: 5px;
  font-size: 25px;
  font-weight: 900;
}
.year-propulate {
  display: flex;
  justify-content: space-between;

  width: 100%;
}
.year-propulate p {
  margin: 5px 0px;
}
.right-graph {
  flex: 10 1 200px;
  position: relative;
}
#lineChart {
  background-color: rgba(114, 167, 216, 1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  /* width: 100%; */
}

.bar-alone-container {
   position: relative;
}
.bar-container {
  /* background-color: thistle; */
  display: flex;
  height: 100%;
  flex-direction: column;
/*transition-duration: 1s;*/
  /* padding: 0px 0px; */
  /* margin-top: 10px; */
  /* justify-content: center;
  align-items: center; */

  /* position: absolute; */
}
.baralone-container{
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.header-baralone{
  display: flex;
  margin-bottom: 10px;
  color: var(--main2-color);

  align-items: center;
}
.header-baralone .baralone-icon{
  width: 25px;

margin-right: 5px;
}
/*.bar-container:hover{*/
/*  transform: scale(1.05,1.05);*/
/*  transition-duration: 1s;*/
/*}*/
#sssd {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
@media only screen and (max-width: 600px) {
  .graph-container{
    flex-direction: column;
  }
  .left-graph{
    height: 20%;
    border-bottom-left-radius:0px!important;
    border-top-right-radius:10px!important;
  }
  #lineChart{
    border-top-right-radius:0px!important;
    border-bottom-left-radius:10px!important;
  }
}

.sign-container {
    background-color: #ffffff;

    align-self: center;
    border-radius: 10px;

}
.sign-container:hover .icon-male{
    -webkit-transform: scale(1.4,1.4);
            transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.sign-container:hover .icon-smoke{
    -webkit-transform: scale(1.4,1.4);
            transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.sign-container:hover .icon-money{
    -webkit-transform: scale(1.4,1.4);
            transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.sign-container:hover .icon-saving{
    -webkit-transform: scale(1.4,1.4);
            transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.ui.celled.grid {
  box-shadow: none!important;
}
.ui.celled.grid > .row {
  box-shadow: none;
}
.icon-male {
    position: absolute;
    top: -25px;
    color: #72a7d8;
    transition-duration: 1s;
}
.icon-smoke{
    position: absolute;
    top: -30px;
    width: 55px;
    fill: #0d4e89;
}
.icon-saving{
    position: absolute;
    top: -20px;
    width: 50px;
    fill: #0d4e89;
}
.icon-money{
    position: absolute;
    top: -25px;
    width: 55px;
    fill: #0d4e89;
}
.icon-beer{
    position: absolute;
    top: -20px;
    width: 45px;
    fill: #0d4e89;
}
.grid-img {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100px;
}
.sign-amount {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 8px !important;
}
.sign-amount p {
    margin: 0px 5px;

    font-size: 1.5rem;
}
.sign-sex {
    padding: 5px 20px !important;
}
.sign-sex p {
    font-size: 1.5rem;
}
.bg-sign {
    background: linear-gradient(
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
    ),
    url(/static/media/silk-pattern.0816d0dd.png) center center;
    /* background-size: cover; */
    background-repeat: repeat;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.text-amount {
    color: rgb(78, 78, 78);
}
.text-unit {
    color: rgb(110, 110, 110);
}
.sign-container .ui.celled.grid > .row > .column {
    box-shadow: none!important;
}
@media only screen and (max-width: 768px) {
    .icon-male {
        font-size: 4rem !important;
        top: -25px;
    }

    .sign-amount p {
        font-size: 1rem;
    }

    .sign-sex p {
        font-size: 1rem;
    }

    /*.test-left .child .sign-container .sign-amount p {*/
    /*    font-size: 12px;*/
    /*}*/

    /*.test-left .child .sign-container .sign-sex p {*/
    /*    font-size: 15px;*/
    /*}*/
}
@media screen and (max-width: 1024px) {
    .sign-amount p {
        font-size: .9rem;
    }

    .sign-sex p {
        font-size: .9rem;
    }
}


.head-pie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: start;
    color: var(--main2-color);
}
.wrapper-donut{
    position: relative;
}
.wrapper-donut p{
    position: absolute;
    bottom: 10px;
    left: 50px;
}
.pie-container {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 0px 0px 15px;
    transition-duration: 1s;
}

/*.pie-container:hover{*/
/*    transform: scale(1.05,1.05);*/
/*    transition-duration: 1s;*/
/*}*/
.donut-container {
    display: flex;
    background-color: white;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    transition-duration: 1s;
}
/*.donut-container:hover{*/
/*    transform: scale(1.05,1.05);*/
/*    transition-duration: 1s;*/
/*}*/
.content-desc {
    display: flex;
    margin: 5px 0px;
    width: 400px;
    border-top: 1px solid gray;
}

.content-desc div {
    margin: 5px;

}

.point-date {
    /* background-color: aqua; */
    padding: 5px;
    border-radius: 5px;
    /* flex: 0 1 auto; */
}

.text-desc {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex: 2 1 150px;
}

.text-num {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 100px;
}

.text-num p {
    margin: 0px;
}

.data-description {
    margin-top: 30px;
    /*flex: 1 1 auto;*/
}

.box-color {
    padding: 10px;
    /*size: 0px;*/
    border-radius: 10px;
    margin-right: 5px;

}
.data-label {
    display: flex;
    align-items: center;
    margin: 5px;
}

.text-label {
    font-size: 12px;
}

.legend-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/*
.text-perc {
  flex: 2 1 auto;
} */
@media only screen and (max-width: 600px) {
    .text-label {
        font-size: 8px;
    }
    .data-label {

        margin: 2px;
    }
    .box-color {
        padding: 5px;
        /*!*size: 0px;*!*/
        /*border-radius: 10px;*/
        /*margin-right: 5px;*/

    }
    .content-desc {

        width: 100% !important;

    }
}
@media only screen and (max-width: 1000px) {

    .content-desc {

        width: 100% !important;

    }
}

.head-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--main2-color);
}
.horizon-bar-container {

  background-color: white;
  padding: 10px;
  border-radius: 10px;
  /*margin-top: 20px;*/
  transition-duration: 1s;
}
.horizon{
  position: relative;
  height: 67.2vh;
}
/*.horizon-bar-container:hover{*/
/*  transform: scale(1.05,1.05);*/
/*  transition-duration: 1s;*/
/*}*/
@media screen and (max-width: 1024px) {

  .horizon{
    /*position: relative;*/
    height: 40.4vh;
  }
}

.select-container {
  /* background-color: aqua; */
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;

  /*height: 100px;*/


}

.link-select {
  position: absolute;
  border-top: 1px solid gray;
  padding: 10px;
z-index: 0;
  width: 80%;
  -webkit-transform: translateY(30%);
          transform: translateY(30%);
}
.menu-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 16px 17px;
  border-radius: 50px;
  border: 2px solid #ebebeb;
  font-weight: 900;
  transition: 0.5s;
}
.flex-menu-butt{

  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  z-index: 0;
  /*padding: 10px 0px;*/
}
.flex-menu-butt1{

  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  /*padding: 10px 0px;*/
}
.mt{
  margin-top: 12px;
}
.flex-menu-butt .label-but- ,.flex-menu-butt .label-but-false {

  font-size: 12px;
  margin: 0px;
}

.flex-menu-butt .label-but-active {
 color: var(--main-color);
  margin: 0px;
}
.menu-select p {
  margin: 0px;
}
.menu-select:hover {
  background-color: var(--main-color);
  border: 2px solid var(--main2-color) !important;
  transition: 0.5s;

  color: white;
  cursor: pointer;
}
.text-group {
  font-size: 20px;
}
.actives {
  background-color: var(--main2-color);
  /*border: 2px solid #00396d!important;*/
  /*z-index: 1;*/
  transition: 0.5s;
  color: white;
  cursor: pointer;
}
.active-2 {
  background-color: var(--main-color);

}
.active-3 {
  background-color: royalblue;
}
.active-4 {
  background-color: royalblue;
}
.active-5 {
  background-color: royalblue;
}
@media only screen and (max-width: 767px) {
  .menu-select {
    padding: 8px 9px;
  }
  .mt{
    margin-top: 12px;
  }
  .text-group {
    font-size: 10px;
  }

  .select-container{
    margin-top: 10px;
  }
}

.export-button{
  display: flex;

  border: none;
  position: relative;
  box-shadow: 5px 5px 4px #c7c7c7;
  outline: none;
  background-color: #FFFFFF !important;
  cursor: pointer;
  font-size: 14px !important;
  color: #616161;
  border-radius: 10px;
  align-items: center;
  padding: 10px 30px;
  margin: 5px;
}
.export-button .icon-export{
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  /*width: 40vw;*/
}
.export-button .progress-export{
  display: flex;


  justify-content: center;
  align-items: center;
  /*width: 40vw;*/
}
.export-button .progress-export .progress-bar{
  /*display: flex;*/
  margin: 0px!important;
  width:15vw;
}
.export-button .progress-export .label-loading{
  display: flex;
  justify-content: center;
  margin: 0px;
  /*margin-right: 5px;*/
  width: 35px;
}
.export-button .progress-export .cancel-export{
  margin: 0px;
  margin-left: 5px;
}
.ui.container.doubling.divided.two.column.grid.main-dash-container {
    margin: 0 !important;
    width: 100% !important;
    padding: 0px 20px;
}

.stretched.row.main-show-area-container {
    padding: 0 !important;
}

.bg-top-container, .bg-top-container2, .container-select {
    color: #696969;
}

.stretched.row.fix-padding {
    padding-top: 0 !important;
}

.loader-container .route-loader {
    margin: 10px 30px !important;
}

.fix-container-left {
    /*padding: 0 !important;*/
    padding-left: 0 !important;
}

.fix-slider-container {

    padding: 0px 35px !important;
}

.fix-slider3-container {

    padding: 0px 35px !important;
}

.ui.container.two.column.grid.main-g3.fix-slider3-container {
    width: 100% !important;
}

.ui.container.stackable.three.column.grid.fix-slider-container {
    width: 100% !important;
}

.ui.container.stackable.two.column.grid.fix-slider-container {
    width: 100% !important;
}

.fix-container-right {
    /*padding: 0 !important;*/
    padding-right: 0 !important;
}

.fix-container {
    padding: 0 !important;
}

.group-left {
    /*margin-right: 7px;*/

}

.test-left {
    display: flex;
    flex-direction: column;
}

.population-right-container .child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.population-right-container .child .child-col1 {
    margin-right: 5px;
    flex: 1 1 auto;
}

.population-right-container .child .child-col2 {
    margin-left: 5px;
    flex: 1 1 auto;
}

.slick-next,
.slick-prev,
.slick-dots {
    display: none !important;
}

.container-select {
    /*margin-top: 30px;*/
    padding: 0px;
    padding-bottom: 40px;
    height: 100%;
}

.col-mar0 {
}

.header-dash {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
}

.header-dash-text {
    color: #808080;
    font-size: 15px;
    font-weight: lighter;
}

.header-population-text {
    color: var(--main-color);
    font-size: 21px;
    font-weight: bold;
}

.header-home-text {
    color: var(--main-color);
    font-size: 21px;
    font-weight: bold;
}

.header-dash-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.main-title-dash .left-header-container {
    display: flex;
}

.main-title-dash .right-header-container {
    display: flex;
}

.ui.divided.grid:not([class*="vertically divided"]) > .row > .column {
    box-shadow: none !important;
}

.ui.celled.grid > .row {
    box-shadow: none !important;
}

.ui.attached.segment {
    border: none !important;
}

.ui.segment {
    border: none !important;
}

.ui.attached.menu:not(.tabular) {
    border: none !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2) !important;
}

.ui.menu .active.item {
    background: rgba(0, 0, 0, .1) !important;
}

.ui.divided.grid:not([class*="vertically divided"]) > .column:not(.row), .ui.divided.grid:not([class*="vertically divided"]) > .row > .column {
    box-shadow: none !important;
}

.main-g3 {
    margin-top: 5px !important;
    display: flex !important;
}

.ui.grid > .column:not(.row) {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
}

.loading-screen {
    background-color: #0d4e89;
}

.main-title-dash {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.export-button {
    display: flex;
    border: none;
    position: relative;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
    border-radius: 10px;
    align-items: center;
    padding: 10px 30px;
    margin: 5px;
}

.filter-button {
    display: flex;
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-family: 'Kanit', sans-serif;
    padding: 10px 30px;
    margin: 5px;
}

.filter-modal-screen .modal-content .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal-screen .modal-content .modal-detail {
    margin: 20px 0px;
}

.filter-modal-screen .modal-content .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}

.filter-modal-screen .modal-content .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 12px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

.filter-modal-screen .modal-header {
    display: flex;
    justify-content: space-between;

}

.filter-modal-screen .modal-header .left-modal-header {
    display: flex;
    font-size: 18px;
    color: #00396D;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.filter-modal-screen .modal-header .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal-screen .modal-header .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.title-area-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.title-area-container .title-area-icon {
    padding: 15px 12px;
    background-color: var(--main-color);
    border-radius: 40px;
    position: absolute;
    left: -5px;
    top: -12px;
    color: white;
}

.title-area-container .area-bar {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-left: 20px;

}

.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-up-hor-left {
    -webkit-animation: scale-up-hor-left 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-hor-left 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
        opacity: 1.0;
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0px);
                transform: translateX(0px);
        opacity: 1.0;
    }
}

@-webkit-keyframes scale-up-hor-left {
    0% {
        -webkit-transform: scaleX(0.2);
                transform: scaleX(0.2);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
    }
    100% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
    }

}

@keyframes scale-up-hor-left {
    0% {
        -webkit-transform: scaleX(0.2);
                transform: scaleX(0.2);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
    }
    100% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
        -webkit-transform-origin: 0% 0%;
                transform-origin: 0% 0%;
    }

}

.title-area-container .area-bar .area-header {
    display: flex;
    /*justify-content: center;*/
    padding: 10px;
    background-color: #1967AD;
    /*width: 30%;*/
    border-top-right-radius: 23px;
}


.title-area-container .area-bar .area-text {
    display: flex;
    flex-wrap: wrap;
    /*flex: 1 1 10px;*/
    /*flex-direction: column;*/
    padding: 15px 20px;
    background-color: whitesmoke;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
}

.title-area-container > span {
    background-color: var(--main-color);
    padding: 10px 30px;
    border-top-right-radius: 23px;
    /*border-bottom-right-radius: 23px;*/
    border-bottom-left-radius: 23px;
    align-self: flex-start;

}

.border-radius-bot-right {
    border-bottom-right-radius: 23px;
}

.title-area-container > span p {
    margin-left: 30px;
    color: white;
}

.title-area-container .area-bar .area-text .all-commu-name {
    /*flex:1 1 auto;*/
    display: flex;
    /*padding: 0px 15px;*/
    color: #1967AD;
}

.title-area-container .area-bar .area-text .all-commu-name p {
    margin: 0px !important;
}

.title-area-container .area-bar .area-text .all-commu-name .community-name-space {
    color: #2e465b;
    padding: 0px 10px;
    /*margin: 0px!important;*/
}

.flex-menu-butt .label-but- {
    font-size: 16px !important;

}

.flex-menu-butt .label-but-false {
    font-size: 16px !important;
    margin-bottom: 0px;
    margin-top: 5px;
}

.flex-menu-butt .label-but-actives {
    font-size: 16px !important;
    margin-top: 5px;
    margin-bottom: 0px;
}

.flex-menu-butt .label-but2-actives {
    font-size: 16px !important;
    margin-top: 5px;
}

.flex-menu-butt .label-but2-false {
    font-size: 16px !important;
    /*margin-bottom: 5px;*/
    margin-top: 5px;
}

@media only screen and (max-width: 767px) {
    /*.container-select {*/
    /*    !*margin-top: 30px;*!*/
    /*    padding: 10px;*/
    /*}*/
    .flex-menu-butt .label-but- {
        font-size: 12px !important;

    }

    .flex-menu-butt .label-but-false {
        font-size: 10px !important;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .flex-menu-butt .label-but-actives {
        font-size: 10px !important;
        margin-top: 5px;
        margin-bottom: 0px;
    }

    .flex-menu-butt .label-but2-actives {
        font-size: 10px !important;
        margin-top: 5px;
    }

    .flex-menu-butt .label-but2-false {
        font-size: 10px !important;
        /*margin-bottom: 5px;*/
        margin-top: 5px;
    }

    .fix-slider-container {

        padding: 0px 2px !important;
    }

    .fix-slider3-container {

        padding: 0px 14px !important;
    }

    .ui.container.two.column.grid.main-g3.fix-slider3-container {
        width: auto !important;
    }

    .header-container {
        margin-top: 45px !important;
    }

    .main-dash-container .column.main-left-population-container {
        padding-bottom: 0 !important;
    }

    .bg-top-container {
        padding: 15px 0px !important;
        margin-top: 20px !important;
        background-color: rgba(255, 255, 255, .5);
        /*border-radius: 10px;*/
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .bg-top-container2 {
        padding: 15px 0px !important;
        margin-top: 20px !important;
        background-color: rgba(255, 255, 255, .5);
        /*border-radius: 10px;*/
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .container-select {
        padding: 15px 0px !important;
        margin: 0px 18px;
        margin-top: 30px !important;
        background-color: rgba(255, 255, 255, .5);
        border-radius: 10px;
    }

    .progbar-g1 {
        margin-top: 100px !important;
    }

    .ui.stackable.celled.grid > .column:not(.row), .ui.stackable.celled.grid > .row > .column, .ui.stackable.divided:not(.vertically).grid > .column:not(.row), .ui.stackable.divided:not(.vertically).grid > .row > .column {
        border-top: none !important;
    }

    .title-area-container {
        margin-bottom: 15px;
    }

    .right-header-container {
        display: flex;
        flex-direction: column;
    }

    .show-area-container {
        padding: 0px !important;
    }

    .ui.container.container-select {
        padding: 0px 11px !important;
    }

}

@media screen and (max-width: 1024px) {
    .container-select {
        /*margin-top: 30px;*/
        padding: 0px 28px;
    }

    .content-desc {
        width: 300px;
    }

    .fix-slider3-container {
        padding: 0px 6px !important;
    }

    .fix-slider-container {

        padding: 0px 6px !important;
    }
}

.main-g5-left-container .child-g5-col{
  /*display: flex;*/
  margin-top: 14px;
}
.main-g5-top-container .child1{
  /*display: flex;*/
  margin-bottom: 15px;
  /*margin-right: 5px;*/
}

.main-g5-left-container .child-g5-col .sign-container .sign-sex p{
  font-size: 1.2rem;
}

.main-g5-left-container .child-g5-col .sign-container .sign-amount .text-unit{
  font-size: 0.8rem;
}
.main-g5-left-container .child-g5-col .sign-container .sign-amount .text-amount{
  font-size: 1rem;
}
.main-g5-left-container .child-g5-col .sign-container .icon-beer{
  /*width: 20px;*/
  /*height: 10px;*/
}
.main-g5-right-container .child1 .main-prog-container {
  margin-top: 14px;
  margin-bottom: 15px;
  padding-bottom: 72px;
}
.main-g5-right-container .child2 .main-prog-container {
  /*margin: 15px 0px;*/
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.main-g5-right-container .child3 .main-progOnce-container {

  margin-bottom: 14px;
}

@media only screen and (max-width: 767px) {
  .main-g5-left-container .icon-male{
    font-size: 50px!important;
    margin-top: 15px;
  }
}
.main-g4-bar-container .child-bar{
  /*display: flex;*/
  /*flex-direction: column;*/
  margin-bottom: 20px;
}
.main-g4-left-container .child-g4-col {
  display: flex;
  /* flex-direction: column; */
  /* display: flex; */
  justify-content: space-between;
}
.main-g4-left-container .icon-male{
  font-size: 40px!important;
}
.main-g4-left-container .child-g4-col p{
  font-size: 15px;
  padding-left: 10px;
}
.main-g4-left-container .child-g4-col .child1,.child1-top {
  margin-right: 5px;

}
.main-g4-left-container .child-g4-col .ui.celled.grid {
  margin:5px 0px!important;

}
.main-g4-left-container .child-g4-col .child1-top .sign-amount{
  padding-left: 37px!important;
}
.main-g4-left-container .child-g4-col .child2-top .sign-amount{
  padding-left: 12px!important;
}
.main-g4-left-container .child-g4-col .child1,.child1-top .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child2,.child2-top .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child1 .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child2 .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child2,.child2-top {
  margin-left: 5px;
}
.main-g4-left-container .child-g4-row .pie-container{
  margin-top: 5px;
}
.main-g4-right-container{
  margin-top: 15px;
  /*display: flex;*/
}

@media only screen and (max-width: 768px) {
  .main-g4-left-container .icon-male{
    font-size: 40px!important;
  }
  .main-g4-left-container .child-g4-col p{
    font-size: 13px!important;
  }
  .main-g4-left-container .child-g4-col {
     flex-direction: column;
    /* display: flex; */
  }
  .main-g4-left-container .child-g4-col .child2,.child2-top {
    margin-left: 0px;
  }
  .main-g4-left-container .child-g4-col .child1,.child1-top {
    margin-right: 0px;

  }
}
.main-g3-right-container .main-prog-container{
    padding-bottom: 56px;
}
.main-g2-right-container {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  /*justify-content: space-between;*/
}
/*.main-g2-left-container .child-g2-bot {*/
/*  margin-top: 15px;*/
/*}*/
.main-g2-left-container .child-g2-top .sign-amount{
  padding: 12px 0px!important;
}
.main-g2-left-container .child-g2-top .sign-sex{
  padding: 8.5px 20px!important;
}
.main-g2-right-container .child-g2-col {
  margin-top: 14px;
}
.child-g2-col,.child-g2-col-top {
  /*margin-bottom: 10px;*/
}
@media only screen and (max-width: 767px) {
  .main-g2-left-container .sign-container .icon-male {
  font-size: 50px!important;
    margin-top: 12px;
  }
}
.main-g1-left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-g1-right-container .donut-container{
  padding: 17.5px 10px;
  margin-top: 10px;
}
.main-g1-left-container .child-g1-row{
  flex: 1 1 auto;
  /*margin: 10px 0px;*/
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .main-g1-left-container .child-g1-row{
    margin-bottom: 10px!important;
  }
}
.main-mid-left-container {
  display: flex;
  flex-direction: column;
}
.child-sign {
  display: flex;
  justify-content: space-between;
}
.child-sign-col1 {
  margin: 0px 5px 0px 0px;
  flex: 1 1 auto;
}
.child-sign-col2 {
  margin: 0px 0px 0px 5px;
  flex: 1 1 auto;
}
.child-pie-col {
  /*margin: 10px 0px;*/
}
.mid-right-container{
  margin-top: 14px;
}

/*.flex-pop-info{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
.graph-top {
    padding: 14px 0px;
    padding-top: 15px;
    /*height: 355px;*/
    /*flex: 1 1 auto;*/
}
/*.graph-bot {*/
/*    padding: 10px 0px;*/
/*    !*padding-top: 15px;*!*/
/*    !*height: 355px;*!*/
/*    !*flex: 1 1 auto;*!*/
/*}*/
.edit-map-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    position: relative;
}

.edit-map-screen .detail-content {
    position: absolute;
    left: 0;
    top: 90px;
    padding: 20px;
    z-index: 20;
    background-color: #FFF;
    width: 450px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.edit-map-screen .content-action {
    text-align: center;
    padding-top: 20px;
}

.edit-location.active {
    background-color: var(--main-color);
    color: #FFF !important;
}

.edit-location.disabled{
    background-color: #eaeaea;
    cursor: not-allowed;
}

.edit-location.active i {
    color: #FFF;
}

.edit-location:active {
    background-color: #e8e8e8;
}

.lightbox-container {
    position: relative;
}

.lightbox-container:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.lightbox-container .lightbox-img-thumbnail {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100% !important; /* This if for the object-fit */
    height: 100% !important; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
}

.marker-text {
    top: -28px;
    position: relative;
}

.edit-map-screen .backdrop {
    display: flex;
    flex-direction: column;
    color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: 99;
    align-items: center;
    justify-content: center;
}

.edit-map-screen .address-no {
    width: 70px;
    position: relative;
    z-index: 99;
    left: -35px;
    top: -60px;
    text-align: center;
}

.edit-map-screen .backdrop h1 {
    margin: 0 !important;
    margin-top: -20px;
}

.edit-map-screen .backdrop i.disabled.icon {
    font-size: 130px;
    color: #FFF !important;
    opacity: 1 !important;
}

.edit-map-screen .header-content {
    display: flex;
    align-items: center;
    width: 300px;
    position: absolute;
    z-index: 1;
    left: 0;
    background-color: #ffffff;
    padding: 15px 10px 10px;
    border-radius: 0 10px 10px 0;
    font-family: 'Kanit', sans-serif;
}

.edit-map-screen .header-content i {
    font-size: 30px;
    margin-top:5px;
    color: var(--main-color);
    cursor:pointer;

}

.edit-map-screen .header-content .header-text {
    color: #595959;
    font-size: 24px;
    font-weight: 700;
}

.edit-map-screen .header-content .subheader-text {
    color: #808080;
}

.edit-map-screen .map-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.edit-map-screen .icon-marker {
    cursor: pointer;
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 80px;
    right: 40px;
}

.edit-map-screen .icon-cluster {
    cursor: pointer;
    box-shadow: 0 0 14px #4b494933;
    width: 80px;
    height: 80px;
    border: solid 6px #FFF;
    border-radius: 40px;
    background: #0d4e89;
    font-size: 150%;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-map-screen .map-content .filter-content {
    position: absolute;
    top: 20px;
    right: 20px;
}

.edit-map-screen .map-content .filter-house {
    height: 190px;
    background-color: #FFF;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: 0.5s;
}

.edit-map-screen .map-content .filter-house.select-filter {
    height: 270px;
}

.edit-map-screen .map-content .filter-house.hide {
    height: 49px;
    overflow: hidden;
}

.edit-map-screen .map-content .filter-house > .topic {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    margin-top: -3px;
}

.edit-map-screen .map-content .filter-house > .topic > .map {
    color: var(--main-color);
    opacity: 1 !important;
}

.edit-map-screen .map-content .filter-house > .topic > .filter {
    color: var(--main-color);
    opacity: 1 !important;
}

.edit-map-screen .map-content .filter-house > .topic > .caret.down {
    color: #1967AD;
    opacity: 1 !important;
}

.edit-map-screen .map-content .filter-house > .topic > .window.minimize {
    color: #1967AD;
    opacity: 1 !important;
    margin-bottom: 10px;
}

.edit-map-screen .map-content .filter-house > .topic > h4 {
    color: var(--main2-color);
    margin: 0 !important;
    padding-top: 5px;
    padding-right: 80px;
    font-family: 'Kanit', sans-serif;
}

.edit-map-screen .map-content .filter-house > .search.dropdown {
    margin: 5px 0;
    border-radius: 10px;
}

.edit-map-screen .map-content .icon-checkcox {
    margin-top: -10px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

.edit-map-screen .map-content .filter-house > .ui.checkbox {
    display: block;
    margin-bottom: 15px;
}

.edit-map-screen .map-content .filter-house .switch-icon {
    position: absolute;
    right: 10px;
}

.edit-map-screen .type-map img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    border: solid 5px #FFF;
    cursor: pointer;
}

.edit-map-screen .type-map {
    float: right;
}

.edit-map-screen button.gm-control-active.gm-fullscreen-control {
    display: none !important;
}

@media screen and (max-width: 425px) {
    .edit-map-screen .map-content .filter-content {
        top: 90px;
    }
}

@media screen and (max-width: 480px) {
    .edit-map-screen .content-action {
        text-align: center;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .edit-map-screen .sub-detail-data-house{
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {

    .edit-map-screen .detail-content {
        left: 0;
        right: 75px;
        bottom: 0;
        width: auto;
        width: initial;
        top: auto;
        top: initial;
    }

    .edit-map-screen .content-marker-detail .detail-data-house{
        display: none;
    }
}


